<template> 
    <div class="w-full">
        <facture-create/>
    </div>
</template>

<script>
import FactureCreate from '@/components/facture/FactureCreate.vue';
    export default {
        components: {
            FactureCreate
        },
    }
</script>

<style lang="scss" scoped>


</style>
<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('userMsg') }}</h1>
        <form action="" @submit.prevent="saveinfo" ref="userform"> 
          <div class=" p-3">
              <img v-if="image" :src="image" alt="" srcset="" class="mx-auto w-20 h-20 rounded-full">
              <input  @change="onFileSelected " type="file">       
            </div>

            <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
                </div>
                <div>{{ errors?.name }}</div>
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.username" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('usernameMsg') }} </label>
                </div>
                <div>{{ errors?.username }}</div>
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="email" required id="default_standard" v-model="form.email" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('Email') }} </label>
                </div>
                <div>{{ errors?.email }}</div>
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="password" required id="default_standard" v-model="form.password" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('passwordMsg') }} </label>
                </div>
                <div>{{ errors?.password }}</div>
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="password" required id="default_standard" v-model="form.password_confirmation" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('confirmpasswordMsg') }} </label>
                </div>
                <div>{{ errors?.password_confirmation }}</div>
             </div>
            
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../api.js'
  import result from '../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        
        data(){
          
            return{
            form:{
                name:"",
                username:"",
                email:"",
                password:"",
                password_confirmation:"",
                image:"",
            },
            loading:null,
            image:null,
            stocks:[],
            category:[],
            errors:{},
            UserId:this.$store.state.UserId,
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
        
  
        mounted()
        {
           
           if(this.UserId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.AllUsers;
                this.image=this.$store.state.AllUsers.image
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
                console.log(this.UserId)
        },
        computed:{
        ...mapState(['UserId','AllUsers']),
    },
  /*  watch:{
          "catid"(a){
            console.log(a)
            if(this.$store.state.categoryId==null){
              this.form={};
                this.saveEditBtn="Save"
              }else{
                this.form=this.$store.state.category;
                this.saveEditBtn="Edit"
              }
                console.log(a)
          },
          "form.name"(val)
          {
            console.log(this.form.name)
            
          }
        },*/
        
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.UserId=null;
                    this.$emit('close')
                },
                onFileSelected(event){
                    console.log(event);
                    this.form.image=event.target.files[0]
                   // console.log(this.form.image);
                    this.image= URL.createObjectURL(event.target.files[0]);
                },
                onFileChange(event) {
                    const file = event.target.files[0]; 
                    this.form.image = event.target.files[0]
                    this.image= URL.createObjectURL(file);
                  },
                SaveData(){
                    this.loading=true;
                },
              
            saveinfo() {
             
            this.loading = true;
            if (this.form["name","username","email","password","confirm","image"] == "") return;
            console.log(this.form.image)
            const data= new FormData();
            data.append("name", this.form.name);
            data.append("email", this.form.email);
            data.append("username", this.form.username);
            data.append("password", this.form.password);
            data.append("password_confirmation", this.form.password_confirmation);
            if(this.form.image)
            {
              data.append("image", this.form.image)

            }
            
            

            if (this.categoryId == null) {
                api.post(
                    "/register",
                    data
                )
                    .then((resp) => {
                      this.loading = false;
                      this.category = resp.data;
                      this.successAllert()
                      this.form = [];
                      this.data={}

                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                      console.log(err.data.errors);
                        this.errors = err.data.errors;
                        
                    });
            } else {
                api.patch(
                    "/register/" + this.categoryId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.category = resp.data;
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        this.loading = false;
                       // this.errorAlert()
                        console.log(err);
                       // this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
        }

        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">Article</h1>
        <form action="" @submit.prevent="saveInformation" ref="ArticleForm"> 
            <div class=" p-3">
              <label for="" class=" capitalize">{{ $t('nameMsg') }}</label>
              <input type="text" :placeholder="$t('nameMsg')" required v-model="form.name"
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.name }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('serialNumberMsg') }}</label>
              <input type="text" :placeholder="$t('serialNumberMsg')" v-model="form.serial_no" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.serial_no }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('specificationMsg') }}</label>
              <input type="text" :placeholder="$t('specificationMsg')" v-model="form.specification" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.specification }}</div> 
            </div>

            <div class=" p-3">
              <label for="">{{ $t('Section') }}</label>
              <input type="text" placeholder="section" v-model="form.section" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.section }}</div> 
            </div>
            
            <div class=" p-3">
              <label for="">{{ $t('valueMsg') }}</label>
              <input type="number" :placeholder=" $t('valueMsg') " v-model="form.value" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.value }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('MinQuantityMsg') }}</label>
              <input type="number" :placeholder="$t('MinQuantityMsg')" v-model="form.quantity_min" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.quantity_min }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('QuantityMsg') }}</label>
              <input type="number" :placeholder="$t('QuantityMsg')" v-model="form.quantity" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.quantity }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('LocationPriceMsg') }}</label>
              <input type="number" :placeholder="$t('LocationPriceMsg')" v-model="form.price_location" required
               class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
               <div>{{ errors?.price_location }}</div> 
            </div>
            <div class=" p-3">
              <label for="">{{ $t('categoryMsg') }}</label>
              <select name="" id="" v-model="form.category_id" required class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
                <option v-for="category in categories"  :key="category.id" :value="category.id" class="" selected>
                  {{ category.name }}
                </option>
              </select>              
            </div>

            <div class="p-3">
              <div class="flex justify-between">
                <h3> {{ $t('OtherDetailMsg') }} </h3>
                <span @click="addNewDetail" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-auto bg-transparent text-center p-1">
                  {{$t('AddMsg')}}
                </span>
              </div>
              
              <div v-for="(item, index) in autres" :key="index">
                  <div class=" p-3">
                      <label class="basis-1/2">
                        <input type="text" v-model="item.label"  :placeholder="$t('labelMsg')" class="bg-transparent border-none focus:outline-none focus:border-buja-primary focus:border-b"/>
                        <button @click="deleteDetail(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
                      </label>
                      <textarea  v-model="item.detail" :placeholder="$t('DetailMsg')"
                      class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> </textarea>
                </div>
              </div>
               
            <div>
            <!-- <img src="@/assets/icon-plus.svg" alt="" /> -->
            <!-- Add New Item -->
          </div>
        </div> 
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t ('btnEmpty') }}</button>
              </div>
            </form>
            <div  @click="toggleModal" class="rounded-sm p-3 bg-gray-600  cursor-pointer hover:bg-gray-400 ">{{ $t ('btnClose') }}</div>
         
    </div>   
  </template>
  
  <script>
  
  import { mapMutations,mapState } from 'vuex';
  import {uid} from 'uid'
  import api from '../../../api.js'
  import result from '../../mixins/result';
  export default{
    mixins:[result],
        data(){
            return{
            form:{
                uuid:uid(),
                serial_no:"",
                name:"",
                section:"",
                specification:"",
                quantity_min:"",
                quantity:"",
                price_location:"",
                category_id:"",
                autres:"",
                // autres:[]

            },
            loading:null,
            autres:[],
            categories:[],
            articles:[],
            errors:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
      /*  watch:{
          "$store.state.articleId"(a){
            if(this.$store.state.articleId==null){
              this.form={};
                this.saveEditBtn="Save"
              }else{
                this.form=this.$store.state.article;
                this.form.category_id=this.$store.state.article.category_id;
                this.saveEditBtn="Edit"
              }
                console.log(a)
          }
        },*/
        mounted()
        {
           this.getCategory()
           if(this.$store.state.articleId==null){
              this.form={};
              this.form.uuid=uid(),
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.article;
                this.form.category_id=this.$store.state.article.category_id;
                console.log(this.$store.state.article.category_id)
                console.log(this.form.category_id)
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
  
  
        },
        computed:{
        ...mapState(['articleId','article'])
    },
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();
                    this.$emit('close')   
                },
                SaveData(){
                    this.loading=true;
                },
                getCategory() {
                    api.get("/category")
                    .then(resp => {
                        this.categories = resp.data
                        console.log(this.categories)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            saveInformation() {            
            this.loading = true;
            console.log(this.form.uuid);
            if (this.form["serial_no","value","name","category_id"] == "") return;
              
            if (this.articleId == null) {
              this.form.autres=this.autres
              let v={}
              v=this.form
                api.post(
                    "/article",
                    v
                )
                    .then((resp) => {
                        this.loading = false;
                        this.articles = resp.data;
                        this.form={uuid:uid(),serial_no:"",name:"",quantity_min:"",quantity:"",section:"",specification:"",autres:"",price_location:"",category_id:""},
                        this.successAllert()
                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;

                    });
            } else {
                api.patch(
                    "/article/" + this.articleId,
                    this.form)
                    .then((resp) => {
                        this.articles = resp.data;
                        this.loading = false;
                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        
                    });
  
            }
  
        },



    addNewDetail() {
            this.autres.push({
                //id: uid(),
                label: "",
                detail: "",
              });
    },

    deleteDetail(id) {
      this.autres = this.autres.filter((item) => item.id !== id);
    },


        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }

  </style>
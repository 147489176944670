<template>
  <div>
    <home v-if="isLogged"/>
    <Login v-else/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      // isLogged:true
    }
  },
  mounted()
{
  this.$store.commit("INITIALIZE")
},
  created(){
    this.checkScreen();
    window.addEventListener("resize", this.checkScreen);
  },
  methods:{
    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 750) {
        this.$store.state.is_expanded= false
        localStorage.setItem("is_expanded",this.$store.state.is_expanded)
      }else{
        this.$store.state.is_expanded= true
        localStorage.setItem("is_expanded",this.$store.state.is_expanded)

      }
      // this.$store.state.is_expanded = false;
    },
  },
  components:{
    'Login':require('@/components/Global/login.vue').default,
    'home':require('@/views/Home.vue').default
},
computed:{
   ...mapGetters(['user']),
   isLogged: function() { return this.$store.getters.user}
}

}
</script>

<style lang="scss">

</style>

<template>
    <div class="w-full mt-20">
           
        <div>
            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                    <li class="mr-2" role="presentation">
                        <button class="flex p-4 border-b-2 rounded-t-lg" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">
                            <svg aria-hidden="true" class="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>Profile
                        </button>
                    </li>
                    <li class="mr-2" role="presentation">
                        <button class="flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false">
                            <svg aria-hidden="true" class="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>
                            Settings
                        </button>
                    </li>
                </ul>
            </div>
            
            <div id="myTabContent" class=" h-screen bg-gray-50">
                <div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="w-full max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div class="flex justify-end px-4 pt-4">
                            <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                                <span class="sr-only">Open dropdown</span>
                                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>
                            </button>

                            <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul class="py-2" aria-labelledby="dropdownButton">
                                <li>
                                    <a @click="this.edit=true" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <Loading v-show="loading" class=" h-screen"/>
                        <div v-if="edit" class="my-4">
                            <form action="" @submit.prevent="saveinfo" ref="userform">
                                    <div class=" p-3">
                                    <img v-if="image" :src="image" alt="" srcset="" class="mx-auto w-20 h-20 rounded-full">
                                     <input  @change="onFileSelected"   type="file" ref="filename">
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="text" required id="default_standard" v-model="form.name" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
                                        </div>
                                        <div>{{ errors?.name }}</div>
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="text" required id="default_standard" v-model="form.username" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('usernameMsg') }} </label>
                                        </div>
                                        <div>{{ errors?.username }}</div>
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="email" required id="default_standard" v-model="form.email" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('Email') }} </label>
                                        </div>
                                        <div>{{ errors?.email }}</div>
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="password" required id="default_standard" v-model="form.password" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('oldpasswordMsg') }} </label>
                                        </div>
                                        <div>{{ errors?.old_password }}</div>
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="password" required id="default_standard" v-model="form.newpassword" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('NewpasswordMsg') }} </label>
                                        </div>
                                        <div>{{ errors?.new_password }}</div>
                                    </div>
                                    <div class="p-3">
                                        <div class="relative z-0">
                                            <input type="password" required id="default_standard" v-model="form.password_confirmation" class="input-buja-wide peer" placeholder=" " />
                                            <label for="default_standard" class="float-label-buja">{{ $t('confirmpasswordMsg') }} </label>
                                        </div>
                                        <div>{{ errors?.new_password_confirmation }}</div>
                                    </div>
                                    <div class="flex gap-8 mt-5">
                                        <button type="submit" class="btn-green-add">{{$t('btnEdit') }}</button>
                                        <button type="reset" class="btn-red-empty">{{ $t('btnCancel') }}</button>
                                    </div>
                            </form>
                         </div>
                         <div class="flex flex-col items-center pb-10" v-else>
                            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" :src="profile" alt="Bonnie image"/>
                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{ name }}</h5>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ email }}</span>
                        </div>
                    </div>
                </div>
                <div class="hidden p-4 rounded-lg bg-gray-50" id="settings" role="tabpanel" aria-labelledby="settings-tab">
            
                    <div class="text-xl text-center mt-3">{{ $t('selectLanguage') }}</div>
                    <div class="flex gap-3 justify-center mt-3">
                            <button class="btn" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">
                                <flag :iso="entry.flag" :squared=false /> {{entry.title}} |
                            </button>
                        </div>
                </div>
            </div>
        </div>

        <!-- <ul
  class="mb-5 flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row"
  role="tablist"
  data-te-nav-ref>
  <li role="presentation">
    <a
      href="#tabs-home2"
      class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-home2"
      data-te-nav-active
      role="tab"
      aria-controls="tabs-home2"
      aria-selected="true"
      >Pending</a
    >
  </li>
  <li role="presentation">
    <a
      href="#tabs-profile2"
      class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-profile2"
      role="tab"
      aria-controls="tabs-profile2"
      aria-selected="false"
      >Approuved</a
    >
  </li>
  </ul> -->

        <!-- <div>

            <div
    class="hidden opacity-0  transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-home2"
    role="tabpanel"
    aria-labelledby="tabs-home-tab2"
    data-te-tab-active>
    kkkkkk
  </div>
  
  <div
    class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-profile2"
    role="tabpanel"
    aria-labelledby="tabs-profile-tab2">
    hhhhhhh

    </div>

        
        </div> -->
 </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import api from '../../api'
import result from '@/mixins/result';
    export default {
        mixins:[result],

	data() {
            return {
                languages: [
                    { flag: 'us', language: 'en', title: 'English' },
                    { flag: 'fr', language: 'fr', title: 'French' }
                ],
                form:{
                name:"",
                username:"",
                email:"",
                password:"",
                newpassword:"",
                password_confirmation:"",
                image:"",
                },
                users:[],
                
                errors:[],
                name:'',
                edit:false,
                email:'',
                image:null,
                profile:'',
                username:'',
                id:'',
                status:'',
                loading:null,
            };
        },
        
        components:{
          'Loading':require('@/components/Global/Loading.vue').default
      },	
        mounted(){
            
            this.getuser()
            
        },
        methods:{
            editable(){
                this.edit=true
            },
            onFileSelected(event){
                   
                    console.log(event);
                    this.form.image=event.target.files[0]
                   // console.log(this.form.image);
                    this.image= URL.createObjectURL(event.target.files[0]);
                },
                onFileChange(event) {
                    const file = event.target.files[0]; 
                    this.form.image = event.target.files[0]
                    this.image= URL.createObjectURL(file);
                  },

            getuser(){
                let userlogged= JSON.parse(localStorage.getItem('userBujEvent'))
                this.name=Object.values(userlogged)[0].name
                this.profile=Object.values(userlogged)[0].image
                this.email=Object.values(userlogged)[0].email
                this.id=Object.values(userlogged)[0].id    
                this.form.name=Object.values(userlogged)[0].name
                this.image=Object.values(userlogged)[0].image
                this.form.email=Object.values(userlogged)[0].email
                this.form.username=Object.values(userlogged)[0].username
                this.id=Object.values(userlogged)[0].id  
                console.log(this.image)
                var url = this.image.split(/[\\\/]/).pop();
                
             },

		changeLocale(locale) {
			localStorage.setItem('LocalLanguage',locale);  
			 this.$router.go(0)
                i18n.locale = locale;
				console.log(locale);
            },
         
            saveinfo()
            {
                this.loading=true
                const data={old_password:this.form.password,
                new_password:this.form.newpassword,
                new_password_confirmation:this.form.password_confirmation
                
                }
                api.post(
                    "/changepassword/",data
                    )
                    .then((resp) => {
                    //    this.users = resp.data;
                        this.loading = false;
                        this.successAllert();
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        this.loading = false;
                        if(!err.data.errors)
                        {
                            this.errorAlert(err.data.data)

                        }
                        
                       
                        
                        this.errors = err.data.errors;
                        console.log(this.errors);
                        
                    });


            }
	}
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <main>
        <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <button class="p-2 bg-green-500 rounded-sm w-auto mt-20" @click="toggleModal">{{ $t ('AddMsg') }} Article</button>	
        <!-- table -->
        <div v-if="status">
            <div class="">
        
 <ul 
      class="mb-5 flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row"
      
      >
  <li  v-for="(item,index) in categories  " :key="index ">
    <a
     :href="'#tab'+item.id"
      @click="show(item,index)"
      ref="id"
      class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent active:border-success active:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      :class="index === selected && 'active'"
      data-te-nav-active
      role="tab"
      :aria-controls="'tab'+item.id"
      aria-selected="true"
      >{{item.name}}</a
    >
  </li>
 <!-- <li role="presentation">
    <a
      href="#tabs-profile2"
      class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-profile2"
      role="tab"
      aria-controls="tabs-profile2"
      aria-selected="false"
      >Ecrans</a
    >
  </li>-->
 
 
</ul>
</div>
<div >
<div 
         class="hidden opacity-0 opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
         
         :id="'tab'+id"
         role="tabpanel" 
         :aria-labelledby=  "'tabs-homes-tab'+id"
         data-te-tab-active
         >
            <div class="mx-auto">   
                <div class="flex flex-col">
                <div class="w-full">
                    
                    <div class="p-8 border-b border-gray-200  relative overflow-x-auto  border sm:rounded-lg">
                        <input type="text"  v-mode="search" @keypress.enter="searchEvery" class="mt-3 mb-5">
                        <table class="divide-y divide-gray-300 w-full" >
                            <thead class="bg-black">
                                <tr>
                                    
                                    
                                    <th  class="px-6 py-2 text-xs text-white">
                                        #
                                    </th>
                                     
                                    <th class="px-8 py-2 text-xs text-white capitalize">
                                        {{ $t('Designation') }}
                                    </th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('Name') }}
                                    </th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('QuantityMsg') }}
                                    </th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                         Code d'identification 
                                    </th>

                                    <th  class="px-6 py-2 text-sm text-white capitalize" v-for="(item,index) in (cat)" :key="index">{{ item.label }}</th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('Etat') }}
                                    </th>
                                    <th class="px-6 py-2 text-xs text-white capitalize">
                                        {{ $t('Commentaire') }}
                                    </th>
                                                            
                                    <th class="px-6 py-2 text-xs text-white">
                                        Actions
                                    </th>
                                    
                                </tr>
                                
                            </thead>
                            
                            <tbody class="bg-white divide-y divide-gray-300">
                                <tr v-for="article in filterarticles" :key="article.id" class=" hover:bg-green-200 hover:cursor-pointer text-center whitespace-nowrap hover:text-buja-primary">
                                    <th class="px-6 py-4 text-sm text-gray-500">
                                        {{ article.value }}
                                    </th>
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{JSON.parse(article.designation).label}}
                                        </div>
                                    </td> 
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{article.name}}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{article['quantity']}}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{article['identification']}}
                                        </div>
                                    </td>
                                    <td col="1" v-for="(item,index) in (cat)" :key="index" class="px-6 py-4">
                                        <div v-for="(item1,index) in JSON.parse(article.autres)" :key="index" class="text-sm text-gray-900">
                                            {{ item1[item.label] }}

                                        </div>
                                     
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{article['Etat']}}
                                        </div>
                                    </td>
                                    <td class="px-6 py-4">
                                        <div class="text-sm text-gray-900">
                                            {{article['commentaire']}}
                                        </div>
                                    </td>
                                    
                                    <td class="flex justify-center gap-8 px-6 py-4">
                                       <button class="inline-block text-center " @click="editArticle(article,article.id,this.categories),toggleModal1()">
                                            <span class="material-icons">edit_square</span>                                           
                                           </button>

                                        
                                        <button class="inline-block text-center" @click="deleteArticle(article.id)">
                                            <span class="material-icons">delete</span>
                                        </button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            </div>
    </div>
    
</div>
        </div>
        <div v-else>              
            <tableHolder/>
        </div>    

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../mixins/result';
export default {
    mixins:[result],

data () {     
    return {
        articles:[],
        status:null,
        categories:[],
        cat:[],
        search:"",
        active:'',
        id:'',
        filterarticles:[],
       
        selected: null
        
    }
},
components:{
    'formData':require('./ArticleForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
computed:{
    filterItems()
    { 
       
        return this.searchInArray(this.filterarticles,this.search)
    },
},
watch:{
    articles(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
    this.getcategories()
    this.filterarticles=this.articles
   
    
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    // this.WRAP_MODAl();
    this.$router.push('/article_form')
    this.$store.state.articleId=null
},
searchInArray(arrayList, searchText) {
            //Methode pour faire une rechercher dans le tableau
            if((arrayList)&& searchText){
                return arrayList.filter(
                e => JSON.stringify(e)
                         .toLowerCase()
                         .includes(searchText.toLowerCase())
            )
            }
            return arrayList
        },

 show(index,item)
    {
        this.cat=[]
        console.log(index)
        this.id=index.id
        let cat=this.categories.filter(item=> item.id==index.id)
        this.filterarticles=this.articles.filter(item=> item.category_id==this.id)
        let cate=cat[0].Description
        let a=this.$refs.id[item]
        this.selected = item
        if(cate!=="")
        {
            this.cat=JSON.parse(cate)
        //  console.log(this.cat)

        }
        let at=[]

     //   console.log(this.articles)

      //  console.log(this.cat)

       /* this.FilterItems=this.searchInArray(this.filterarticles,this.search)
        
        console.log(this.FilterItems)*/
        
        
        
    },


    toggleModal1()
    {
        this.$router.push('/article_form')
    
    },
    getcategories(){
        api.get("/category")
        .then(resp => {
            this.categories = resp.data
            

        })
        .catch(err => {
            console.error(err)
        })

    },
    fetchData() {
        api.get("/article")
        .then(resp => {
            this.articles = resp.data
            console.log(this.articles)
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteArticle(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
            api.delete("article/" + id)
        .then(resp => {
            //this.articles = resp.data.data
            
            this.showDeleteConfirmation()
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
        
    }}
    )
        
        
    },

    editArticle(art,id,category){
        this.$store.state.articleId=id

        this.$store.state.article=art
        this.$store.state.category=category
    
    }
},
computed:{
...mapState(['wrapModal','articleId','article']),
// articles(){
//         return  this.$store.state.article
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

.active {
  color: red;
  
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}


</style>
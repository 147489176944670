<template>
    <main>
    
    <div class="p-5 mt-12">
        
        <!-- table -->
        <div class="mx-auto">
        <div class="mb-6" v-if="stat">
        <div class="flex flex-col">
            <div class="w-full">
                <div class="p-8 border-b border-gray-200 shadow">
                    <table class="divide-y divide-gray-300 w-full" id="datatable">
                        <thead class="bg-black">
                            <tr>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    #
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                   sortie_id 
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    client
                                </th>
                                
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    fait le:
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    status
                                </th>
                                
                            
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    fait par:
                                </th>                            
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr  class="text-center whitespace-nowrap" v-for="ret in  JSON.parse(return_articles)" :key="ret.id">
                                <th class="px-6 py-4 text-sm text-gray-500">
                                    {{ ret.id }}
                                </th>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    {{ ret.location.id }}
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.client.name}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.created_at}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                       <span v-if="ret.status==0">pending</span> <span v-else>closed</span>
                                    </div>
                                </td>
                                
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.user_id}}
                                    </div>
                                </td>
                                
                                <td class="flex justify-center gap-8 px-6 py-4">
                                    <button v-if="ret.status==0" class="inline-block text-center ">
                                        
                                        <router-link   :to="{name:'returnid',params:{id:ret.location.id}}" class="nav-link collapsed  text-black" 
                                            aria-expanded="true" aria-controls="collapseStock"><span class="material-icons">edit_square</span>
                                       </router-link>                                           
                                    </button>
                                    <button class="inline-block text-center">
                                       
                                       <router-link :to="{name:'viewretun',params:{id:ret.id}}" class="nav-link collapsed  text-black" 
                                               aria-expanded="true" aria-controls="collapseStock"><font-awesome-icon icon="fa-solid fa-file-invoice" />view
                                       </router-link>
                                   </button>
                                   <button v-if="ret.status==0" class="inline-block text-center" @click="close(this.form,ret.id)">
                                       Close
                                   </button>
                                    
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
        <div v-else>              
            <tableHolder class="w-full"/>
         </div>
    </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {

data () {     
    return {
        return_articles:[],
        form:{
            status:'1'
        },
        stat:null
    }
},
mixins:[result],
components:{
    'formData':require('./returnform.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,


},

mounted(){
    this.fetchData()
   // console.log((this.return_articles))
},
watch:{
    return_articles(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
        },
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    // this.$store.state.locationId=null;
},
fetchData() {
        api.get("/return-article")
        .then(resp => {
            this.return_articles = JSON.stringify(resp.data)
            console.log(JSON.stringify(this.return_articles))
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.stat=true
            }
            else{
                this.stat=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteLocation(id){
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("location/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            //this.categories = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )},

    close(form,id){
        this.confirmres().then((result) => {
        if (result.isConfirmed) {
            api.patch("return-article/" + id,form)
        .then(resp => {
            this.successAllert()
            //this.categories = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )},

    
},
computed:{
...mapState(['wrapModal','locationId','location']),
// locations(){
//         return  this.$store.state.location
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99; 
}
</style>
<template>
    <main>
<div class="p-5 mt-12">
          <button @click="toggleModal" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
              <span class="material-icons">keyboard_double_arrow_left</span>
          </button>
        <!-- table -->
        <div class="mx-auto">
        <div class="flex flex-col">
            <div class="w-full">
                <div class="p-8 border-b border-gray-200 shadow">
                    <table class="divide-y divide-gray-300 w-full" id="datatable">
                        <thead class="bg-black">
                            <tr>
                               
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                   Designation 
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    quantité
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Etat
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    commentaire
                                </th> 
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    Date Amené
                                </th>                           
                                
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr  class="text-center whitespace-nowrap" v-for=" ret in returnarticles" :key="ret.id">
                                <th class="px-6 py-4 text-sm text-gray-500">
                                    {{ ret.article.name  }}
                                </th>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    {{ ret.quantity }}
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.state}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.comments}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{ret.created_at.substr(0, 10)}}
                                    </div>
                                </td>
                                
                               
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    </div>
</main>
</template>

<script>
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    mixins:[result],
    props:['id'],
    data()
    {
        return{
            returnarticles:[]
        }
    },
    mounted()
    {
        this.getreturn(this.id)
    },
    watch:{
        returnarticles(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
        },
},
    methods:{
        getreturn(id)
        {
        api.get("/getreturn/",{params:{return_id:id}})
        .then(resp => {
            this.returnarticles = resp.data
            /*if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }*/ 
        })
        .catch(err => {
            console.error(err)
        })
        },
        toggleModal()
        {
            this.$router.push('/return')
        }
    }
}
</script>
<style>

</style>
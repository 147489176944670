<template>
    <main>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <h1 class="text-2xl text-center capitalize mt-20">{{ $t('EventPriceMsg') }}</h1>
        <button class="btn-list" @click="toggleModal(),$store.state.locationId=null">{{ $t('AddMsg') }} {{ $t('EventPriceMsg') }} </button>			
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="datatable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                #
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{$t ('EventMsg')}}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{$t ('TickeTypeMsg')}}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{$t ('amountMsg')}}
                            </th>                          
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="eventPrice in eventPrices" :key="eventPrice.id" class="text-center whitespace-nowrap">
                            <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                {{ eventPrice.id}}
                            </th>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{eventPrice.event.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{eventPrice.type_ticket.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{eventPrice.montant}}
                                </div>
                            </td>
                                                        
                            <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                <button class="inline-block text-center " @click="editEventPrice(eventPrice,eventPrice.id),toggleModal()">
                                    <span class="material-icons">edit_square</span>                                           
                                </button>
                                <button class="inline-block text-center" @click="deletEventPrice(eventPrice.id)">
                                    <span class="material-icons">delete</span>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
        <div v-else>              
            <tableHolder/>
        </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {

data () {     
    return {
        eventPrices:[],
        status:null
    }
},
mixins:[result],
components:{
    'formData':require('./eventPriceForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    eventPrices(val) {
          console.log(val)
          $('#datatable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#datatable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
},
fetchData() {
        api.get("/event-price")
        .then(resp => {
            this.eventPrices = resp.data
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deletEventPrice(id){
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("event-price/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )},

    editEventPrice(evPri,id){
        this.$store.state.eventPriceId=id
        this.$store.state.eventPrice=evPri
    
    }
},
computed:{
...mapState(['wrapModal','eventPriceId','eventPrice']),
// eventPrices(){
//         return  this.$store.state.eventPrice
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99; 
}
</style>
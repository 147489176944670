import { createStore } from 'vuex'

export default createStore({
  state: {
  //baseUrl: 'http://127.0.0.1:8000/api',
 //  baseUrl:'https://apimanage.bujaevents.bi/api',
 baseUrl: 'https://testapi.bujaevents.bi/api',
 
    wrapModal:null,
    isModalActive:null,
    is_expanded :localStorage.getItem("is_expanded") === "true",
    lacalLanguage:localStorage.getItem('LocalLanguage'),
    users:[],
    FactureId:null,
    Facture:[],
    UserId:null,
    AllUsers:[],
    Role_Id:null,
    AllRoles:[],
    user:null,
    stockId:null,
    stock:[],
    category:[],
    categoryId:null,
    articleId:null,
    article:[],
    locationid:[],
    service:[],
    serviceId:null,
    client:[],
    locations_art:[],
    clientId:null,
    location:[],
    locationId:null,
    Requisition:[],
    requisitionId:null,
    categoryEventId:null,
    categoryEvent:[],
    eventId:null,
    event:[],
    eventPriceId:null,
    eventPrice:[],
    eventOrderId:null,
    eventOrder:[],
    TickeTypeId:null,
    TickeType:[],
    eventTicketId:null,
    eventTicket:[],
    organizer:[],
    organizerId:null,
  },
  mutations: {
    LOGIN(state,user)
    {
      state.user = user;
       localStorage.setItem('userBujEvent', state.user);    
    },
    LOGOUT(state)
    {
      state.user=''
      localStorage.removeItem('userBujEvent')    

    },
    INITIALIZE(state)
    {
      if(localStorage.getItem('userBujEvent')){
        state.user=JSON.parse(localStorage.getItem('userBujEvent'))
      }
      else{
        this.commit("LOGOUT");
      }
    },
    WRAP_MODAl(state){
      state.wrapModal=!state.wrapModal
    },
    SIDE_BAR(state){
      state.is_expanded= !state.is_expanded
		localStorage.setItem("is_expanded",state.is_expanded)
    },
    TOGGLE_MODAL(state){
      state.isModalActive=!state.isModalActive
    }
  },
  getters:{ 
    user : state =>{
            if(state.user == null){
                return localStorage.getItem('userBujEvent')
            }
            return state.user
        },
    token : (state, getters)=> getters?.user?.token,
    wrapModal:state=>{
      return state.wrapModal
    }

  },
  actions: {
  },
  modules: {
  }
})

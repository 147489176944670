<template>
    <!-- <div :class="`${loading ? 'fixed p-[56px] bg-[rgb(20,22,37)] max-w-[700px] h-screen text-white ' : 'buja-form-content'}`" ref="form-content"> -->
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
       <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('RoleMsg') }}</h1>
        <form action="" @submit.prevent="saveinfo" ref="userform"> 
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('userMsg') }} :</label>
              <select name="" id="" @change="getuserrole()" required v-model="form.user_id" class="select-buja">
                <option v-for="user in users"  :key="user.id" :value="user.id" class="">
                  {{ user.name }}
                </option>
              </select>
               <span>{{ errors?.user_id[0] }}</span>
            </div>
            <div class="p-3">
                <div class="relative z-0">
                    <input type="text" disabled="disabled" required id="default_standard" v-model="form.user_role_name" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('RoleMsg') }} </label>
                </div>
                <div>{{ errors?.user_role_name[0] }}</div>
             </div>
            
            <div class="p-3">
              <label for="" class="text-gray-500">{{ $t('NewRoleMsg') }} :</label>
              <select name="" id="" required v-model="form.role_id" class="select-buja">
                <option v-for="role in roles"  :key="role.id" :value="role.id" class="">
                  {{role.name}}
                </option>
              </select>
               <span>{{ errors?.role_id[0] }}</span>
            </div>
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../api.js'
  import result from '../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        
        data(){
          
            return{
            form:{
                role_id:"",
                user_id:"",
                user_role_id:"",
                user_role_name:[]
            },
            loading:null,
            users:[],
            roles:[],
            roleuser:[],
           
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
        
  
        mounted()
        {
          this.getuser_role()
           //this.getuserrole()
           
           if(this.UserId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.AllUsers;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
                console.log(this.UserId)
        },
        computed:{
        ...mapState(['UserId','AllUsers']),
    },
 
        
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.UserId=null;
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },
                getuser_role()
                {
                  api.get("/users")
                  .then(resp => {
                      this.users =resp.data.users
                      this.roles=resp.data.roles
                      console.log(this.users)
                  })
                  .catch(err => {
                      console.error(err)
                  })

                },
               
                getuserrole()
                {
                  let user=this.users.filter((item)=>item.id==this.form.user_id)
                  let user_role=user[0].roles[0]
               //   user_role.forEach(element => {
                  //  this.form.user_role_name.push({id:element.id,name:element.name})
                  //});
                  //this.form.user_role_name=user_role[0].name
                  console.log(user_role)
                 // console.log(this.form.user_role_name)
                },
                
            saveinfo(){
             
            this.loading = true;
            if (this.form["user_id","role_id"] == "") return;
  
            if (this.categoryId == null) {
                api.post(
                    "/addRole",
                    this.form
                )
                    .then((resp) => {
                      this.loading = false;
                      this.roleuser = resp.data;
                      this.successAllert()
                      this.form = {};
                    })
                    .catch((err) => {
                      this.loading = false;
                      console.log(err.data.errors);
                        this.errors = err.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/register/" + this.categoryId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.category = resp.data;
                        
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        this.loading = false;
                       // this.errorAlert()
                        console.log(err);
                       // this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
        }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
<template>
    <div class="w-full">
        <requisitionList/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'requisitionList':require('@/components/requisitions/requisitionList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
	<Modal v-if="isModalActive"/>
	<Sidebar/>
		
</template>

<script>

import { mapState } from 'vuex';

export default {
	 data() {
		 return {
			 
		}
	},
	methods:{
		
	},
	components:{
    'Sidebar':require('../components/Global/Sidebar.vue').default,
    // wrapMod:'',
    'Modal':require('../components/Global/Modal.vue').default,
	'navbar':require('@/components/Global/navbar.vue').default
  },
	computed:{
		...mapState(['wrapModal','isModalActive'])
	},
}
</script>

<style lang="scss">
:root {
	--primary: #4ade80;
	--primary-alt: #22c55e;
	--grey: #64748b;
	--dark: #1e293b;
	--dark-alt: #334155;
	--light: #f1f5f9;
	--sidebar-width: 300px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Fira sans', sans-serif;
}

body {
	background: var(--light);
}

button {
	cursor: pointer;
	appearance: none;
	border: none;
	outline: none;
	background: none;
}

.app {
	display: flex;
	width: 100%;
	main {
		flex: 1 1 0;	
		// @media (max-width: 1024px) {
		// 	padding-left: 6rem;
		// }
	}
}

</style>
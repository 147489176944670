<template>
        <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">{{$t('stockMsg')}}</h1>
         <form action="" @submit.prevent="saveInformation" ref="StockForm"> 
            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
              </div>
              <div>{{ errors?.name }}</div>
            </div>
           
             <div class="flex gap-8 mt-5">
              <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
              <button type="reset" class=" btn-red-empty">{{$t ('btnEmpty')}}</button>
            </div>
        </form>
        <button  @click="toggleModal" class="btn-gray-close">{{$t('btnClose')}}</button>
    </div>   
</template>

<script>
import store from '@/store';
import { mapMutations,mapState } from 'vuex';
import api from '../../../api'
import result from '../../mixins/result'

export default {
    mixins: [result],
    data() {
        return {
            form: {name: "" },
            errors: {},
            stocks: [],
            saveEditBtn: "save",
            loading: null,
           saveEditBtn:"Save"
            // add:this.$store.state.stockId
        }
    },
    components: {
        'Loading': require('../Global/Loading.vue').default
    },
    mounted()
    {
        
          if(this.$store.state.stockId==null){
            this.form={};
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }else{
              this.form=this.$store.state.stock;
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }
             
        

    },
   /* watch:{
        "$store.state.stockId"(a){
          if(this.$store.state.stockId==null){
            this.form={};
              this.saveEditBtn="Save"
            }else{
              this.form=this.$store.state.stock;
              this.saveEditBtn="Edit"
            }
              console.log(a)
        }
      },*/
    // computed:{
    //     ...mapState(['stockId','stock'])
    // },

    methods: {
        ...mapMutations(['WRAP_MODAl', 'TOGGLE_MODAL']),

        toggleModal() {
            this.WRAP_MODAl();
            this.$emit('close')
        },
        saveInformation() {
            this.loading = true;
            console.log(this.form);
            if (this.form["name"] == "") return;

            if (this.$store.state.stockId == null) {
                api.post(
                    "/stock",
                    this.form
                )
                    .then((resp) => {
                        this.stocks = resp.data;
                        this.form.name=''
                        this.loading = false;
                        this.successAllert()
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/stock/" + this.$store.state.stockId,
                    this.form)
                    .then((resp) => {
                        this.stocks = resp.data.data;
                        this.loading = false;
                        this.successAllert()
                        this.$store.state.wrapModal=null
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });

            }

        }
    
        // SaveData() {
        //     this.loading = true;
        // }
    }
}
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }
</style>
<template>
        <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
       <!-- <div class="buja-form-content FormCl" ref="form-content"> -->      
        <!-- <p>Array:{{ $store.state.service }}</p>
        <p>id:{{ $store.state.serviceId }}</p> -->
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">Service</h1>
         <form action="" @submit.prevent="saveInformation" ref="serviceForm"> 
            <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
                </div>
                <div>{{ errors?.name }}</div> 
            </div>
            <div class="p-3">
                <div class="relative z-0">
                    <input type="number" required id="default_standard" v-model="form.amount" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('amountMsg') }} </label>
                </div>
                <div>{{ errors?.amount }}</div> 
            </div>        
             <div class="flex gap-8 mt-5">
              <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
              <button type="reset" class="btn-red-empty">{{$t ('btnEmpty')}}</button>
            </div>
        </form>
        <button  @click="toggleModal" class="btn-gray-close">{{$t ('btnClose')}}</button>
    </div>   
</template>

<script>
import store from '@/store';
import { mapMutations,mapState } from 'vuex';
import api from '../../../api'
import result from '../../mixins/result'

export default {
    mixins:[result],
    data() {
        return {
            surname:"",
            form: {name: "" ,amount:""},
            errors: {},
            services: [],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer",
            loading: null,
            add:this.$store.state.serviceId
        }
    },
    components: {
        'Loading': require('../Global/Loading.vue').default
    },
    mounted(){

          if(this.$store.state.serviceId==null){
            this.form={};
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }else{
              this.form=this.$store.state.service;
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }
    
        // form(){
        //     console.log("data changes");
        // }
      },
    computed:{
        ...mapState(['serviceId','service',""])
    },

    methods: {
        ...mapMutations(['WRAP_MODAl', 'TOGGLE_MODAL']),

        toggleModal() {
            this.$store.state.serviceId=null;
            this.WRAP_MODAl();
            this.$emit('close')
        },
        saveInformation() {
            this.loading = true;
            console.log(this.form);
            if (this.form["name","amount"] == "") return;

            if (this.$store.state.serviceId == null) {
                api.post(
                    "/service",
                    this.form
                )
                    .then((resp) => {
                        this.services = resp.data;
                        this.successAllert()
                        this.form.name=''
                        this.form.amount=''
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/service/" + this.$store.state.serviceId,
                    this.form)
                    .then((resp) => {
                        this.services = resp.data.data;
                        this.loading = false;
                        this.successAllert()
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });

            }

        }

    }
}
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }
</style>
<template>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
    </Transition>
    <div class="p-5">
        <button class="btn-list mt-20" @click="toggleModal"> {{ $t('addCategoryMsg') }}</button>			
           <!-- table -->
           <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="dataTable">
                        <thead class="bg-black">
                            <tr class=" border border-b">
                                <th class="px-6 py-2 text-xs text-white">
                                    #
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('nameMsg') }}
                                </th>
                                
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('Container') }}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('Description') }}
                                </th>
                                
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('actionMsg') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr v-for="category in categories" :key="category.id" class="text-center whitespace-nowrap">
                                <th class="px-6 py-4 text-sm text-gray-500">
                                    {{ category.id }}
                                </th>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{category.name}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900 " v-for="(item,index) in JSON.parse(category.subcategory)" :key="index">
                                        {{item.label}}
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900" v-for="(item,index) in JSON.parse(category.Description)" :key="index">
                                        {{item.label}}
                                    </div>
                                </td>
                                <td class="flex justify-center gap-8 px-6 py-4">
                                    <button class="inline-block text-center" @click="editcategory(category,category.id),toggleModal1()">
                                        <span class="material-icons">edit_square</span>                                           
                                    </button>
                                    <button class="inline-block text-center" @click="deletecategory(category.id)">
                                        <span class="material-icons">delete</span>
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
           </div>
           <div v-else>              
                <tableHolder class="w-full"/>
           </div>

            
        

</div>

</template>

<script>
import { mapMutations,mapState} from 'vuex';
import result from '../../mixins/result';
import api from '../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    mixins: [result],

data () {     
    return {
        categories:[],
        status:null,
        
    }
},
components:{
    'formData':require('./categoryForm.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,

},


watch:{
    categories(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    this.$store.state.categoryId=null
},
toggleModal1()
{
    this.WRAP_MODAl();

},
fetchData() {
        api.get("/category")
        .then(resp => {
            this.categories = resp.data
            console.log("the status is ",resp.status);

            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }

        })
        .catch(err => {
            console.error(err)
        })
    },
    deletecategory(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
        api.delete("category/" + id)
        .then(resp => {
            this.showDeleteConfirmation()
            //this.categories = resp.data.data 
            this.fetchData()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }}
    )   
    },
    editcategory(categ,id){
        this.$store.state.categoryId=id
        this.$store.state.category=categ
        
    
    }
},
computed:{
...mapState(['wrapModal']),
// categories(){
//         return  this.$store.state.category
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    position: relative;
    top:-20;
    z-index: 99; 
    transition: 0.8s ease all;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    -top:-20;
    z-index: 99; 
    transform: translateX(700px);
}
</style>
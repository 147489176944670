<template>
    <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
    <Loading v-show="loading" class="h-full"/>
      <h1 class=" text-3xl flex justify-center mb-3">{{ $t('categoryMsg') }}</h1>
      <form action="" @submit.prevent="saveInformation" ref="categoryForm"> 
          <div class="p-3">
            <div class="relative z-0">
                <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
            </div>
            <div>{{ errors?.name }}</div>
          </div>
          <div class="p-3">
              <div class="flex justify-between">
                <h3> {{ $t('Description') }} </h3>
                <span @click="descriptions" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-auto bg-transparent text-center p-1">
                  {{$t('AddMsg')}}
                </span>
              </div>
              <div v-for="(item, index) in description" :key="index">
                  <div class=" p-3">
                      <label class="basis-1/2">
                        <input type="text" ref="desc" :disabled="categoryId!=null" v-model="item.label"  :placeholder="$t('labelMsg')" class="bg-transparent border-none focus:outline-none focus:border-buja-primary focus:border-b"/>
                        <button @click="deletes(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
                      </label>
                      
                </div>
              </div>
               
            <div>
           
          </div>
        </div>
        <div class="p-3">
              <div class="flex justify-between">
                <h3> {{ $t('Container') }} </h3>
                <span @click="containers" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-auto bg-transparent text-center p-1">
                  {{$t('AddMsg')}}
                </span>
              </div>
              
              <div v-for="(item, index) in container" :key="index">
                  <div class=" p-3">
                      <label class="basis-1/2">
                        <input type="text"  v-model="item.label"  :placeholder="$t('labelMsg')" class="bg-transparent border-none focus:outline-none focus:border-buja-primary focus:border-b"/>
                        <button @click="deletec(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
                      </label>
                      
                </div>
              </div>
               
            <div>
           
          </div>
        </div>

         <!-- <div class=" p-3">
            <label for="">{{ $t('stockMsg') }} :</label>
            <select name="" required id="" v-model="form.stock_id" class="select-buja">
              <option v-for="stock in stocks"  :key="stock.id" :value="stock.id">
                {{ stock.name }}
              </option>
            </select>            
          </div>-->

          <div class="flex gap-8 mt-5">
              <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
              <button type="reset" class=" btn-red-empty">{{ $t('btnEmpty') }}</button>
            </div>
            
        </form>
        <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
  </div>   
</template>

<script>
import { mapGetters, mapMutations,mapState } from 'vuex';
import api from '../../../api.js'
import {uid} from 'uid'
import result from '../../mixins/result'
import i18n from '@/plugins/i18n';
export default{
      mixins: [result],      
      data(){       
          return{
            uuid:uid(),
            uuid2:uid(),
          form:{name:"",stock_id:"",subcategory:'',Description:''},
          loading:null,
          stocks:[],
          category:[],
          container:[],
          description:[],
          countsub:0,
          countdesc:0,
          catid:this.$store.state.categoryId,
          saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
          }
      },
      components:{
          'Loading':require('../Global/Loading.vue').default
      },
      
      mounted()
      {
         this.getstoks()
         
         if(this.catid==null){
            this.form={};
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }else{
              this.form=this.$store.state.category;
              let container=this.$store.state.category.subcategory
              JSON.parse(container).forEach(element => {
                this.container.push({
                      id:element.id,
                      label: element.label,
                    });
              });
              let descriptions=this.$store.state.category.Description
              console.log(descriptions)
              if(descriptions.length!==null)
              {
                JSON.parse(descriptions).forEach(element => {
                this.description.push({
                      id:element.id,
                      label: element.label,
                    });
              });

              }
              
              
              
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }
              console.log(this.catid)
        
      },
      computed:{
      ...mapState(['categoryId','category']),
  },
      methods:{
          
              ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
              toggleModal(){
                  this.WRAP_MODAl();  
                   this.$store.state.categoryId=null;
                  this.$emit('close')
              },
              SaveData(){
                  this.loading=true;
              },
              getstoks() {
                  api.get("/stock")
                  .then(resp => {
                      this.stocks = resp.data.data    
                  })
                  .catch(err => {
                      console.error(err)
                  })
            },
          saveInformation() {
           
          this.loading = true;
          if (this.form["name","stock_id"] == "") return;
          console.log('hi')
          this.form.subcategory=this.container
          this.form.Description=this.description
          if (this.categoryId == null) {
              api.post(
                  "/category",
                  this.form
              )
                  .then((resp) => {
                    this.loading = false;
                    this.category = resp.data;
                    this.successAllert()
                    this.form = { name:"",stock_id:""};
                    this.form.subcategory={}
                    this.form.Description={}
                   
                  })
                  .catch((err) => {
                    this.loading = false;
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      this.errorAlert()
                  });
          } else {
              api.patch(
                  "/category/" + this.categoryId,
                  this.form)
                  .then((resp) => {
                    this.loading = false;
                      this.category = resp.data;
                      this.successAllert()
                      //this.$store.state.wrapModal=null;
                  })
                  .catch((err) => {
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      this.errorAlert()
                  });
          }
      },
    containers() {
      this.countsub=this.countsub+1
      this.container.push({
                id:this.uuid+this.countsub,
                label: "",
              });
          console.log(this.container)
    },
    descriptions() {
        this.countdesc=this.countdesc+1
        this.description.push({
          id:this.uuid2+this.countdesc,
                label: "",
              });
     /// console.log(this.description)
     let i=this.$refs.desc
       i.map(e=>{
        console.log(e)
      })
        console.log(i)
           
    },
     deletec(index) {
       this.container = this.container.filter((item) => item.id !== index);
       let i=this.$refs.desc
      console.log(i)
       
     },
     deletes(index) {
       this.description = this.description.filter((item) => item.id !== index);
     },
    },
     
     
  }
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }
</style>
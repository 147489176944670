<template>
    <main class="">
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @close="fetchData()"/>
            </wrapModal> 
        </Transition>
    <div class="p-5">
        <h1 class="text-2xl text-center capitalize mt-20">{{$t ('EventMsg') }}</h1>
        <button class="btn-list" @click="toggleModal">{{$t ('AddEventMsg')}}</button>			
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="dataTable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col" class="px-6 py-2 text-xs text-white">
                                #
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('nameMsg') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('categoryMsg') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                date
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('startTimeMsg') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('endTimeMsg') }}
                            </th>

                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t('organizerMsg') }}
                            </th>
                            
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                image
                            </th>
                                                        
                            <th scope="col" class="px-6 py-2 text-xs text-white">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="event in events" :key="event.id" class=" hover:bg-green-200 hover:cursor-pointer text-center whitespace-nowrap hover:text-buja-primary">
                            <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                {{ event.id }}
                            </th>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{event.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{event.event_category_id}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    <!-- {{event.date.substr(0, 10)}} -->
                                    {{new Date(event.date).toISOString().split("T")[0]}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{(event.start_time)}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{timeFormat(event.end_time)}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{event.organiser.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                
                                <div class="text-sm text-gray-900">
                                    <img :src="event.image" />
                                </div>
                            </td>
                        
                            <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                <button class="inline-block text-center " @click="editEvent(event,event.id),toggleModal1()">
                                    <span class="material-icons">edit_square</span>                                           
                                </button>
                                <button class="inline-block text-center" @click="deleteEvent(event.id)">
                                    <span class="material-icons">delete</span>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
        <div v-else>              
            <tableHolder/>
        </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../../mixins/result';
export default {
    mixins:[result],

data () {     
    return {
        events:[],
        status:null
    }
},
components:{
    'formData':require('./eventForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    events() {
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    this.$store.state.eventId=null
},

    toggleModal1()
    {
        this.WRAP_MODAl();
    
    },
fetchData() {
        api.get("/event")
        .then(resp => {
            this.events = resp.data
            console.log(this.events);
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    deleteEvent(id) {
        this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
            api.delete("event/" + id)
        .then(resp => {
            this.fetchData()
            this.showDeleteConfirmation()
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
        
    }}
    )
        
        
    },

    editEvent(ev,id){
        this.$store.state.eventId=id
        this.$store.state.event=ev
    
    },
    timeFormat(t){
    const y=new Date(t)
    return y.getHours() + ':' + y.getMinutes()
    // return y.toLocaleTimeString('en-US')
 }
},
computed:{
...mapState(['wrapModal','eventId','event']),
// events(){
//         return  this.$store.state.event
//     }
},
 

}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}

</style>
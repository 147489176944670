<template>
    	<main>
		<Transition name="wrapModal">
				<wrapModal v-if="wrapModal">
					<formData @close="fetchData()"/>
				</wrapModal> 
			</Transition>
		<div class="p-5">
			<button class="btn-list mt-20" @click="toggleModal">{{$t ('addStockMsg') }}</button>			
			<!-- table -->
            <div v-if="status">
                <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                    <table class="w-full" id="datatable">
                        <thead class="bg-black">
                            <tr>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    #
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{$t ('nameMsg')}}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{$t('actionMsg')}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr v-for="stock in stocks" :key="stock.id" class="text-center whitespace-nowrap">
                                <th class="px-6 py-4 text-sm text-gray-500">
                                    {{ stock.id }}
                                </th>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        {{stock.name}}
                                    </div>
                                </td>
                                <td class="flex justify-center gap-8 px-6 py-4">
                                    <button class="inline-block text-center " @click="editStock(stock,stock.id),toggleModal1()">
                                        <span class="material-icons">edit_square</span>                                           
                                    </button>
                                    <button class="inline-block text-center" @click="deleteStock(stock.id)">
                                        <span class="material-icons">delete</span>
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> 
            <div v-else>              
                <tableHolder/>
            </div>

		</div>

	</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../mixins/result'
export default {
    mixins: [result],
    
    data () {     
        return {
            stocks:[],
            status:null
        }
    },
    components:{
        'formData':require('./stockForm.vue').default,
        'wrapModal':require('@/components/Global/wrapModal.vue').default,
        'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

    },
    watch:{
        stocks(val) {
              console.log(val)
              $('#datatable').DataTable().destroy();
              this.$nextTick(()=> {
                $('#datatable').DataTable()
              });
            },
    },
    mounted(){
        this.fetchData()
    },
    methods: {

	...mapMutations(['WRAP_MODAl','SIDE_BAR']),

    toggleModal(){
    this.WRAP_MODAl();
    this.$store.state.stockId=null
    },
    toggleModal1()
    {
        this.WRAP_MODAl();
    
    },
    fetchData() {
            api.get("/stock")
            .then(resp => {
                this.stocks = resp.data.data
                console.log("the status is ",resp.status);
                if(resp.status===200 || resp.status===204){
                    this.status=true
                }
                else{
                    this.status=false
                }
            })
            .catch(err => {
                console.error(err)
            })
        },
        deleteStock(id) {
            this.confirmDelete().then((result) => {
        if (result.isConfirmed) {
            api.delete("stock/" + id)
            .then(resp => {
                this.stocks = resp.data.data
                this.fetchData()
                this.showDeleteConfirmation()
            })
            .catch(err => {
                console.error(err)
                this.errorAlert()
            })
    }}
    )
            
            
        },

        editStock(stok,id){
            this.$store.state.stockId=id
            this.$store.state.stock=stok
        
        }
},
computed:{
	...mapState(['wrapModal','stockId','stock']),
    // stocks(){
    //         return  this.$store.state.stock
    //     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' -25,
  'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
  transition: 0.8s ease all;
  position: relative;
    z-index: 99; 
}

.wrapModal-enter-from,
.wrapModal-leave-to {
  transform: translateX(700px);
  position: relative;
    z-index: 99; 
}
</style>
<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{$t ('requisitionMsg')}}</h1>
        <form action="" @submit.prevent="saveInformation" ref="ReqForm"> 
            <div class=" p-3">
              <label for="">Article:</label>
              <select name="" required id="" v-model="form.article_id" class="select-buja">
                <option v-for="article in articles"  :key="article.id" :value="article.id" class="">
                  {{ article.name }}
                </option>
              </select>   
            </div>
            <div class=" p-3">
              <label for="">Stock:</label>
              <select name="" id="" required v-model="form.stock_id" class="select-buja">
                <option v-for="stock in stocks"  :key="stock.id" :value="stock.id" class="">
                  {{ stock.name }}
                </option>
              </select>   
            </div>
            <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.quantity" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('QuantityMsg') }} </label>
                </div>
                <div>{{ errors?.quantity }}</div>
             </div>
                    
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{$t ('btnEmpty')}}</button>
                <div  @click="toggleModal" class="rounded-sm p-3 bg-gray-600  hover:bg-gray-400 cursor-pointer">{{$t ('btnClose')}}</div>
             </div>
          </form>
    </div>   
  </template>
  
  <script>
  
  import { mapMutations,mapState } from 'vuex';
  import result from '@/mixins/result'
  import api from '../../../api.js'
  export default{
        data(){
            return{
            form:{
                stock_id:"",
                article_id:"",
                quantity:"",
            },
            loading:null,
            stocks:[],
            articles:[],
            requisitions:[],
            errors:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        mixins:[result],
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
        // watch:{
        //   "$store.state.requisitionId"(a){
        //     if(this.$store.state.requisitionId==null){
        //       this.form={};
        //         this.saveEditBtn="Save"
        //       }else{
        //         this.form=this.$store.state.requisition;
        //         this.saveEditBtn="Edit"
        //       }
        //         console.log(a)
        //   }
        // },
        mounted()
        {
          if(this.$store.state.requisitionId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.requisition;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
           this.getstoks()
           this.getArticle()
  
  
        },
        computed:{
        ...mapState(['requisitionId','requisition'])
    },
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl(); 
                    this.$emit('close')  
                },
                SaveData(){
                    this.loading=true;
                },
                getArticle() {
                    api.get("/article")
                    .then(resp => {
                        this.articles = resp.data
                        console.log(this.articles)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
              getstoks() {
                    api.get("/stock")
                    .then(resp => {
                        this.stocks = resp.data.data
                        console.log(this.stocks)
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            saveInformation() {
             
            this.loading = true;
            console.log(this.form);
            if (this.form["quantity","article_id","stock_id"] == "") return;
  
            if (this.requisitionId == null) {
                api.post(
                    "/requisition",
                    this.form
                )
                    .then((resp) => {
                      this.loading = false;
                      this.requisitions = resp.data;  
                      this.form.quantity="";                    
                      this.form={ stock_id:"", article_id:"", quantity:"" }
                      this.successAllert()
                    })
                    .catch((err) => {
                      this.loading = false;
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/requisition/" + this.requisitionId,
                    this.form)
                    .then((resp) => {
                        this.requisitions = resp.data;
                        this.loading = false;
                        this.successAllert()
                        this.$store.state.wrapModal=null
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
        }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
<template>
    <div class="">
        <CategoryList/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'CategoryList':require('@/components/categories/categoryList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>
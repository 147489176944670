<template>
    <div class="w-full">
        <eventList/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'eventList':require('@/components/Event/Event/eventList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>
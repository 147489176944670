<template>
    <main>
    <!-- <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData/>
            </wrapModal> 
        </Transition> -->
    <div class="p-5">
        <!-- <button class="p-2 bg-green-500 rounded-sm w-28" @click="toggleModal">Add Event</button>			 -->
        <h1 class="text-2xl text-center capitalize mt-20">{{ $t('EventDetailMsg') }}</h1>
        <!-- table -->
        <div v-if="status">
            <div class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                <table class="w-full" id="dataTable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col" class="px-6 py-2 text-xs text-white">
                                #
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('OrderMsg') }} 
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('StateMsg') }} 
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('scannedByMsg') }} 
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('scanneDateMsg') }} 
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('userMsg') }} 
                            </th>
                                                        
                            <th scope="col" class="px-6 py-2 text-xs text-white">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="Detail in Details" :key="Detail.id" class=" hover:bg-green-200 hover:cursor-pointer text-center whitespace-nowrap hover:text-buja-primary">
                            <th scope="row" class="px-6 py-4 text-sm text-gray-500">
                                {{ Detail.id }}
                            </th>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{Detail.event_order_id}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{Detail.status}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{Detail.scanned_By}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{Detail.scanned_date}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{Detail.user_id}}
                                </div>
                            </td>
                        
                            <td class="flex justify-center gap-8 px-6 py-4 whitespace-nowrap">
                                <!-- <button class="inline-block text-center " @click="editOrder(Detail,Detail.id),toggleModal1()">
                                    <span class="material-icons">edit_square</span>                                           
                                </button> -->
                                <button class="inline-block text-center" @click="deleteOrder(Detail.id)">
                                    <span class="material-icons">delete</span>
                                </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
        <div v-else>              
            <tableHolder/>
        </div>

    </div>

</main>
</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../../mixins/result';
export default {
    mixins:[result],

data () {     
    return {
        Details:[],
        status:null
    }
},
components:{
    // 'formData':require('./.vue').default,
    // 'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,

},
watch:{
    Details(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
mounted(){
    
    this.fetchData()
},
methods: {

...mapMutations(['WRAP_MODAl','SIDE_BAR']),

toggleModal(){
    this.WRAP_MODAl();
    // this.$store.state.eventId=null
},

    toggleModal1()
    {
        this.WRAP_MODAl();
    
    },
fetchData() {
        api.get("/event-ticket-detail")
        .then(resp => {
            this.Details = resp.data.data
            console.log("the status is ",resp.status);
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
        })
        .catch(err => {
            console.error(err)
        })
    },
    // deleteOrder(id) {
    //     this.confirmDelete().then((result) => {
    //     if (result.isConfirmed) {
    //         api.delete("Detail/" + id)
    //     .then(resp => {
    //         this.fetchData()
    //         this.showDeleteConfirmation()
    //     })
    //     .catch(err => {
    //         console.error(err)
    //         this.errorAlert()
    //     })
        
    // }}
    // )
        
        
    // },

    // editOrder(ev,id){
    //     this.$store.state.eventId=id
    //     this.$store.state.Detail=ev
    
    // }
},
computed:{
...mapState(['wrapModal','eventId','Detail']),
// Details(){
//         return  this.$store.state.Detail
//     }
}
}

</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}

</style>
<template>
    <div class="w-full">
        <locationList/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'locationList':require('@/components/locations/locationList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>
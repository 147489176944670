<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">

      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">{{ $t('clientMsg') }}</h1>
        <form action="" @submit.prevent="saveInformation" ref="ClientForm">

            <div class=" p-3">
              <label for="" class="text-gray-500 ">{{$t ('companyNutMsg')}}</label>
              <select name="" id="" required  v-model="form.company_nature" class="select-buja">
                        <option></option>
                        <option value="personne_physique" selected>{{ $t('physicPersonMsg') }}</option>
                        <option value="Socièté">{{ $t('companyMsg') }}</option>
                   </select>
               <div>{{ errors?.company_nature}}</div> 
            </div>
            <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
                </div>
                <div>{{ errors?.name }}</div>
             </div>
             <div class="p-3" v-if="form.company_nature=='personne_physique'">
                <div class="relative z-0" >
                    <input type="text" required id="default_standard" v-model="form.lastName" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('LastNameMsg') }} </label>
                </div>
                <div>{{ errors?.lastName }}</div>
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.raison_social" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('raisonSocialMsg') }} </label>
                </div>
                <div>{{ errors?.raison_social }}</div> 
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.telephone" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('telephoneMsg') }} </label>
                </div>
                <div>{{ errors?.telephone }}</div> 
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="text" required id="default_standard" v-model="form.addresse" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('addressMsg') }} </label>
                </div>
                <div>{{ errors?.addresse }}</div> 
             </div>
             <div class="p-3">
                <div class="relative z-0">
                    <input type="text"  id="default_standard" v-model="form.customer_TIN" class="input-buja peer" placeholder=" " />
                    <label for="default_standard" class="float-label-buja">{{ $t('CustomerMsg') }} </label>
                </div>
                <div>{{ errors?.customer_TIN }}</div> 
             </div>
             
             <div class=" p-3">
              <label for="" class=" ">VAT</label>
              <select name="" id=""  v-model="form.vat_customer_payer" class="select-buja">
                        <option></option>
                        <option value="1" selected>OUI</option>
                        <option value="0">NON</option>
                   </select>
               <div>{{ errors?.vat_customer_payer }}</div> 
            </div>
             
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapMutations,mapState } from 'vuex';
  import api from '../../../api.js'
  import result from '../../mixins/result'
  export default{
      mixins: [result],
        data(){
            return{
            form:{
                name:"",
                telephone:"",
                physic_per:"",
                company:"",
                Lastname:"",
                raison_Social:"",
                addresse:"",
                
                customer_TIN:"",
                vat_customer_payer:"",
            },
            loading:null,
            clients:[],
            errors:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
        mounted(){
            if(this.$store.state.clientId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.client;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
        },
        computed:{
        ...mapState(['clientId','client'])
    },
  
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl(); 
                    this.$store.state.clientId=null; 
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },
               
            saveInformation() {            
            this.loading = true;
            console.log(this.form);
           // if (this.form["telephone","addresse","name"] == "") return;
            // console.log('please complete required fields')
  
            if (this.clientId == null) {
                api.post(
                    "/client",
                    this.form
                )
                    .then((resp) => {
                        this.loading = false;
                        this.clients = resp.data;
                        this.successAllert();
                        this.form={name:"",telephone:"",addresse:"",description:"",customer_TIN:"",vat_customer_payer:""}
                        
                    })
                    .catch((err) => {
                      this.loading = false;
                      this.errorAlert()
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        
                    });
            } else {
                api.patch(
                    "/client/" + this.clientId,
                    this.form)
                    .then((resp) => {
                        this.clients = resp.data;
                        this.loading = false;
                        this.successAllert();
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } 
        }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
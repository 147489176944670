<template>
        <div class="mx-auto">
            <div class="w-full">
                <div class="flex items-center absolute top-2/4 z-50 justify-center left-2/4">
                    <strong>{{ $t('LoadingMsg') }}</strong>
                    <div
                        class=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                    </div>
                </div>
                    <div class="w-full">
                        <div class="p-8 border-b border-gray-200 shadow">
                            <div class="flex flex-col flex-1">
                                <div class="flex py-1 justify-between mt-3">
                                    <AnimatedPlaceholder class="w-[200px] mb-2 h-[50px]" />
                                    <AnimatedPlaceholder class="w-[350px] mb-2 h-[50px]" />
                                </div>
                            <!-- Overview -->
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[50px]" />
                            </div>
                            <!-- Hourly -->
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
                            <!-- Weekly -->
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
        
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
        
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
        
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
        
                            <div class="flex flex-col py-1 items-center">
                                <AnimatedPlaceholder class="w-full mb-2 h-[30px]" />
                            </div>
        
                            <div class="flex py-1 justify-between mt-3">
                                    <AnimatedPlaceholder class="w-[200px] mb-2 h-[50px]" />
                                    <AnimatedPlaceholder class="w-[300px] mb-2 h-[50px]" />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
       </div>              
 </template>
  
  <script >
  export default{
    components:{
        'AnimatedPlaceholder':require('@/components/Global/AnimatedPlaceholder.vue').default
    }
  }
  
  </script>
  
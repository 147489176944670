<template>
   <div class="font-Roboto">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-20">
				<!-- Row -->
				<div class="w-full max-sm:w-screen xl:w-3/4 lg:w-11/12 flex">
					<!-- Col -->
					<div class="w-full h-auto bg-gray-400 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg"
						id="imgBg"></div>
					<!-- Col -->
					<div class="w-full border border-black max-sm:border-none max-sm:w-screen lg:w-3/6 p-5 rounded-lg lg:rounded-l-none">
						<h3 class="pt-4 text-3xl text-center">{{$t ('loginTitleMsg')}}</h3>
						<form @submit.prevent="login" class="px-8 pt-8 pb-8 mb-4 rounded">
							
							<div class="mb-4">
								<label class="block text-sm font-bold text-gray-700" for="email">
									Email
								</label>
								<input
									class="input-buja-wide"
									id="email"
									type="email"
									placeholder="Email"
									v-model="form.email"
								/>
							</div>
							<div class="mb-4">
								<label class="block mb-2 text-sm font-bold text-gray-700" for="email">
									 {{ $t('passwordMsg') }}
								</label>
								<input
									class="input-buja-wide"
									type="password"
									placeholder="Password"
									v-model="form.password"
									id="myInput"
								/>
							</div>
							<div class="mb-4">
								<input type="checkbox" @click="passwordToggle()" id="check" class="cursor-pointer checked:bg-green-600 checked:text-green-400">
								<label for="check" class="cursor-pointer ml-1">{{ $t('passwordToggleMsg') }}</label>
							</div>
							<div class="mb-4 flex justify-center text-red-700">
							  {{ errors[0] }}
							</div>
						
							<div class="mb-6 text-center">
								<button
									class=" flex justify-center gap-1 w-full px-4 py-2 font-bold text-white bg-[#259b50]  rounded-full hover:bg-[#22c55e] focus:outline-none focus:shadow-outline"
									type="submit"
								>
									 <span>
										{{ $t('btnLoginMsg') }}
									 </span>
									<Loading v-if="loading"/>
								</button>
							</div>
							<hr class="mb-6 border-t" />
							<div class="text-center">
								<a
									class="inline-block text-sm text-green-500 align-baseline hover:text-green-800"
									href="#"
								>
									
									{{ $t('forgetPasswordMsg') }}

								</a>
							</div>
							
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
export default {

	data(){
		return{
			form:{
				email:'',
				password:'',

			},
			loading: false,
			errors:[]
			
		}
	},
	components:{
		'Loading':require('@/components/Global/buttonLoading.vue').default,
	},
	methods:{
		validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
       },
	    passwordToggle() {
			var x = document.getElementById("myInput");
			if (x.type === "password") {
				x.type = "text";
			} else {
				x.type = "password";
			}
		},
		login()
		{
			
			this.loading = true ;
			 if(!this.validEmail(this.form.email)){
               this.errors.push('email invalide');
			   this.loading = false ;
           }else{
		 axios.post(this.$store.state.baseUrl + "/login/", this.form)
		 .then( (response) =>{
			 
				 this.$store.commit("LOGIN", JSON.stringify(response.data))
				 this.$router.go(0)
				 this.$router.push('/') 
				
			 
		 })
		 .catch( (error)=> {
			this.loading = false ;
			 if (error.message == "Network Error"){
				 this.errors.push("Vous n'êtes pas connecté au serveur")
			 }else{
				 this.errorMessage = error.response.data.message
				 this.errors.push(error.response.data.message)
			 }
			 
		 })

	 }
	}

	}

}
</script>

<style lang="scss" scoped>
.st0{fill:none;stroke:currentColor;stroke-width:20;stroke-linecap:round;stroke-miterlimit:3;}
#imgBg{
	background-image: url('@/assets/bujaEvent.jpg');
	background-size:contain;
}
</style>
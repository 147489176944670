<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('EventCategoryMsg') }}</h1>
        <form action="" @submit.prevent="saveInformation" ref="categoryForm"> 
            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
              </div>
              <div>{{ errors?.name }}</div>
            </div>
  
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../../api'
  import result from '../../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        data(){
            return{
            form:{name:""},
            loading:null,
            categoryEvent:[],
            catid:this.$store.state.categoryEventId,
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../../Global/Loading.vue').default
        },
        mounted()
        {
             
           if(this.catid==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.categoryEvent;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
                console.log(this.catid)
  
        },
        computed:{
        ...mapState(['categoryEventId','categoryEvent']),
    },
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.categoryEventId=null;
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },

                 saveInformation() {

              this.loading = true;
                       if (this.form["name"] == "") return;

                     
            if (this.categoryEventId == null) {
                api.post(
                    "/event-category",
                    this.form
                )
                    .then((resp) => {
                      this.loading = false;
                      this.categoryEvent = resp.data;
                      this.successAllert()
                      this.form = { name:""};
                     
                    })
                    .catch((err) => {
                      this.loading = false;
                        // console.error(err.response.data.errors);
                        // this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/event-category/" + this.categoryEventId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.categoryEvent = resp.data;
                        this.loading = false;
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
                }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
<template>
  <div class="flex fixed justify-center items-center h-screen w-full z-[100]">
    <div class="max-w-[400px] text-white rounded-[20px] pt-[48px] pb-[48px] pr-[32px] pl-[32px] bg-[#252945]">
      <p class="text-md text-center">{{ $t ('ModalMsg') }}</p>
      <div class="flex mt-[24px]">
        <button @click="closeModal" class="btn-buja-event bg-[#22c55e] hover:bg-[#4ade80] flex-1">{{$t ('btnReturn')}}</button>
        <button @click="closeForm" class="btn-buja-event bg-red-600 hover:bg-red-400 flex-1">{{$t ('btnClose')}}</button>
      </div>
    </div> 
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "modal",
  methods: {
    ...mapMutations(["TOGGLE_MODAL", "WRAP_MODAl"]),

    closeModal() {
      this.TOGGLE_MODAL();
    },

    closeForm() {
      this.TOGGLE_MODAL();
      this.WRAP_MODAl();

    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>

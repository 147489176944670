<template>
  <main>
<div class="w-full">
      <div  ref="form-content" class="p-5 mt-20">
        <button @click="toggleModal" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
            <span class="material-icons">keyboard_double_arrow_left</span>
        </button>
      <Loading v-show="loading" class=" h-screen"/>
        <h1 class=" text-3xl flex justify-center mb-3 ">Article</h1>
        <form action="" @submit.prevent="saveInformation" ref="ArticleForm">
          <div class="grid grid-cols-3">
            <div class=" p-3">
              <label for="">{{ $t('categoryMsg') }}</label>
              <select name="" id="" :disabled="this.$store.state.articleId!==null"  v-model="form.category_id"  required class="select-buja-wide">
                <option v-for="category in categories"  :key="category.id" :value="category.id" class="" selected>
                  {{category.name}}
                </option>
              </select>
            </div>
            <div class=" p-3">
                      <label for="">{{ $t('Designation') }}</label>
                      <select name="" id=""  v-model="form.designation" ref="designation"  :required="this.$store.state.articleId!==null" class="select-buja-wide" @change="onchangedesc" >
                        <option v-for="(sub,index) in subcategory"  :key="index" :value="sub"   selected>
                          {{ sub.label }}
                        </option>
                      </select>
            </div>
            <div class=" p-3">
              <label for="" class=" capitalize">{{ $t('Number') }}</label>
              <input type="text" :placeholder="$t('Number')" required v-model="form.value" @keydown="onchangevalue"
               class="input-buja-wide">
               <div>{{ errors?.value }}</div>
            </div>
            <div class=" p-3">
              <label for="" class=" capitalize">{{ $t('nameMsg') }}</label>
              <input type="text" :placeholder="$t('Name')" required v-model="form.name"
               class="input-buja-wide">
               <div>{{ errors?.name }}</div>
            </div>
            <div class=" p-3">
              <label for="">{{ $t('QuantityMsg') }}</label>
              <input type="number" :placeholder="$t('QuantityMsg')" v-model="form.quantity" required
               class="input-buja-wide">
               <div>{{ errors?.quantity }}</div>
            </div>
            <div class=" p-3">
              <label for="">{{ $t('Etat') }}</label>
              <input type="text" :placeholder="$t('')" v-model="form.Etat" 
               class="input-buja-wide">
               <div>{{ errors?.Etat }}</div>
            </div>
            <div class=" p-3">
              <label for="">{{ $t('commentaire') }}</label>
              <input type="text" :placeholder="$t('')" v-model="form.commentaire" 
               class="input-buja-wide">
               <div>{{ errors?.commentaire }}</div>
            </div>
            <div class=" p-3">
              <label for="">{{ $t('Numero identification') }}</label>
              <input type="text" :placeholder="$t('')" v-model="form.identification" required
               class="input-buja-wide">
               <div>{{ errors?.identification }}</div>
            </div>

          </div>

          <!--<div class="p-3">
              <div class="flex justify-between">
                <h3> {{ $t('Description') }} </h3>
                <span @click="addNewDetail" class="cursor-pointer border-2 flex font-[5px] rounded-[3px] w-auto bg-black text-white mr-10 text-center p-1">
                  <span class="material-icons">add</span>
                  {{$t('AddMsg')}}
                </span>
              </div>-->
                <div class="grid grid-cols-4">
                  <div class=" p-3" v-if="Description!=null " >
                     <h3> {{ $t('Description') }} </h3>
                     <div v-for="(item,index) in  Description" :key="index" class=" p-3">
                     
                       <label for="">{{ item.label }}:</label>
                       <input type="text" :placeholder="item.label"   v-model="item.value"
                      class="input-buja-wide">
                  </div>
                  
                  
               </div>
                  
                  <!--<div v-for="(item, index) in autres" :key="index" class="m-5" >
                          <div class="flex justify-between">
                            <input type="text" v-model="item.label"   :placeholder="$t('labelMsg')" class=""/>
                            <button @click="deleteDetail(item.id)" class="">X</button>
                          </div>
                          <textarea  v-model="item.detail" :placeholder="$t('DetailMsg')" class="py-2 px-1 w-full bg-transparent border-b border-black focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
                         </textarea>
                  </div>
                 </div>-->
              </div>
            <div class="flex gap-8 mt-5">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">{{saveEditBtn}}</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">{{ $t ('btnEmpty') }}</button>
              </div>
          </form>

    </div>
</div>
</main>
</template>

<script>

import { mapMutations,mapState } from 'vuex';
import {uid} from 'uid'
import api from '../../api'
import result from '@/mixins/result';
export default{
  mixins:[result],
      data(){
          return{
          form:{
              uuid:uid(),
              name:"",
              value:"",
              identification:"",
              Etat:"",
              commentaire:"",
              quantity:"",
              category_id:"",
              autres:"",
              designation:""
          },
          loading:null,
          Description:[],
          autres:[],
          categories:[],
          subcategory:[],
          articles:[],
          errors:[],
          array:[],
          catego:"",
          index:0,
          t:null,
          saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
          }
      },
      components:{
          'Loading':require('@/components/Global/Loading.vue').default
      },
   
      mounted()
      {
       // console.log(this.$store.getters.user.roles)
        
         this.getCategory()
         if(this.$store.state.articleId==null){
            this.form={};
            this.form.uuid=uid(),
            //this.onchangecat(this.form.category_id)
            this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
          
            }else{
              
              this.form=this.$store.state.article;
              this.form.category_id=this.$store.state.article.category_id;
              this.form.value=this.$store.state.article.value
             
              this.onchangecat(this.$store.state.category,this.$store.state.article.category_id)
              let i=JSON.parse(this.$store.state.article.designation)
              //this.$refs.designation.attributes[0].value=JSON.stringify(i)
              this.form.designation=i
              //console.log( this.$refs.designation.attributes[0].value)
              console.log(this.form.designation)



              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
            }


      },
      computed:{
        filtercat(){
        return this.change(this.categories,this.form.category_id)

        //console.log(filtercat)
      },
      ...mapState(['articleId','article']),
  },
  watch:{
    "form.category_id"(val)
    {
      console.log(val)
      //this.onchangedes()
      this.onchangecat(this.categories,this.form.category_id)
     
   //   this.change(this.categories,this.form.category_id)
    },
   
  
   
  },

      methods:{
        onchangedesc()
        {
          this.form.value=this.form.designation.index+'.'
          console.log(this.form.designation)
          console.log( this.$refs.designation.attributes[0].value)

        },
        onchangevalue()
        {
          //let t=this.form.value
          if(this.$store.state.articleId!==null)
          {
          this.form.identification=""
         

          }
          

        },
        onchangecat(categories,val)
        {
          this.catego=[]
          console.log(val)
          this.catego=categories.filter((item)=>item.id==val) 
          var Description=this.catego[0]?.Description
          var subcategory=this.catego[0]?.subcategory
          if(Description)
          {
          this.Description=JSON.parse(Description)
          
          if(this.$store.state.articleId==null)
          {
            this.Description.forEach(function(item){item.value=''})

          }else{
            let t=[]

            let desc=this.$store.state.article.autres
            let cate=this.$store.state.article.category.Description
            this.Description.forEach(element1 => {
              JSON.parse(cate).forEach(element2 => {
              JSON.parse(desc).forEach(element3 => {
                  if(element1.label==element2.label)
                  
                  {
                    if(element3[element1?.label])
                    {
                      element1.value=element3[element1.label];
                    }
                  
                  }
            });
              
              
            });
              
              
            });
          console.log(t)

          }

          }
          if(subcategory)
          {
            this.subcategory=JSON.parse(subcategory)
             let id=this.index+1
            this.subcategory.forEach(function(item){item.index=id++});
            this.form.designation=this.$store.state.article.designation
            if(this.$store.state.articleId!==null)
          {
           
             this.form.designation=this.$store.state.article.designation
          
          }
          
           
           
          }
          console.log(this.subcategory)
        //  console.log(this.categories)
      console.log('hi')

        },
          
              ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),

              toggleModal(){
                this.$router.push('/article')  
              },
              change(categories,id)
            {
              if(Array.isArray(categories) ){
              return categories.filter((item)=> item.id==id
                      )} 
              
              console.log(this.categories)
              return categories;

            },
              SaveData(){
                  this.loading=true;
              },
              getCategory() {
                  api.get("/category")
                  .then(resp => {
                      this.categories = resp.data
                      console.log(this.categories)
                  })
                  .catch(err => {
                      console.error(err)
                  })
            },
            
          saveInformation() { 
          this.loading = true;
          console.log(this.form.uuid);
          if (this.form["serial_no","value","name","category_id"] == "") return;
          this.Description.forEach(element => {
              this.array.push({[element.label]:element.value})
            });
            
           // this.form.designation= this.form.designation.label
            this.form.autres=this.array
            console.log(this.form)
            
            
          //  console.log(this.autres)              
        if (this.articleId==null) {
            
            
           //this.form.autres=this.autres
            let v={}
            v=this.form
              api.post(
                  "/article",
                  v
              )
                  .then((resp) => {
                      this.loading = false;
                      this.articles = resp.data;
                      this.form={uuid:uid(),name:"",quantity:"",identification:"",autres:"",category_id:"",Etat:"",commentaire:""},
                      this.array=[]
                      this.Description=[]
                      this.successAllert()
                  })
                  .catch((err) => {
                    this.loading = false;
                    this.form={uuid:uid(),name:"",quantity:"",identification:"",autres:"",category_id:"",Etat:"",commentaire:"",value:""},
                    this.array=[]
                    this.Description=[]
                    this.errorAlert()
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      
                  });
          } else {
            let v={}
            v=this.form
              api.patch(
                  "/article/" + this.articleId,
                  this.form)
                  .then((resp) => {
                      this.articles = resp.data;
                      this.loading = false;
                      this.successAllert()
                      

                  })
                  .catch((err) => {
                    this.loading = false;
                    this.errorAlert()
                      console.error(err.response.data.errors);
                      this.errors = err.response.data.errors;
                      
                  });

          }

      },



 /* addNewDetail() {
          this.autres.push({
              id: uid(),
              label: "",
              detail: "",
            });
  },

  deleteDetail(id) {
    this.autres = this.autres.filter((item) => item.id !== id);
    console.log(id);
  },*/


      },
     
  }
</script>

<style lang="scss" scoped>
// .FormCl{
//   @media (max-width: 1024px) {
// 			margin: auto;
// 		}
// }

</style>
<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('EventPriceMsg') }}</h1>
        <form action="" @submit.prevent="saveInformation" ref="categoryForm"> 
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('EventMsg') }}</label>
              <select name="" id="" v-model="form.event_id" class="select-buja">
                <option v-for="event in events"  :key="event.id" :value="event.id">
                  {{ event.name }}
                </option>
              </select>            
            </div>         
            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.montant" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('PriceMsg') }} </label>
              </div>
              <div>{{ errors?.montant }}</div>
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('TickeTypeMsg') }}</label>
              <select name="" id="" v-model="form.typeTicket_id" class="select-buja">
                <option v-for="ticket in tickets"  :key="ticket.id" :value="ticket.id">
                  {{ ticket.name }}
                </option>
              </select>            
            </div>
  
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
              <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
          </form>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../../api.js'
  import result from '../../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        data(){
          
            return{
            form:{
              event_id:"",
              montant:"",
              typeTicket_id:"",
            },
            loading:null,
            events:[],
            tickets:[],
            EventPrices:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../../Global/Loading.vue').default
        },
        mounted()
        {
  
           this.geTicket()
           this.getEvent()
           
           if(this.$store.state.eventPriceId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.eventPrice;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }          
        },
        computed:{
        ...mapState(['eventPriceId','eventPrice']),
    },
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.eventPriceId=null;
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },
                geTicket() {
                    api.get("/type-ticket")
                    .then(resp => {
                        this.tickets = resp.data
                       
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
              getEvent() {
                    api.get("/event")
                    .then(resp => {
                        this.events = resp.data
                       
                    })
                    .catch(err => {
                        console.error(err)
                    })
              },
            saveInformation() {
             
            this.loading = true;
            if (this.form["event_id","montant","typeTicket_id"] == "") return;
  
            if (this.eventPriceId == null) {
                api.post(
                    "/event-price",
                    this.form
                )
                    .then((resp) => {
                      this.loading = false;
                      this.EventPrices = resp.data;
                      this.successAllert()
                      this.form = { event_id:"",montant:"",typeTicket_id:""};
                     
                    })
                    .catch((err) => {
                      this.loading = false;
                        console.error(err.data.errors.typeTicket_id[0]);
                        // this.errors = err.response.data.errors;
                        this.errorAlert(err.data.errors.typeTicket_id[0])
                    });
            } else {
                api.patch(
                    "/event-price/" + this.eventPriceId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.EventPrices = resp.data;
                        this.loading = false;
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
        }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
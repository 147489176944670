<template>
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('organizerMsg') }}</h1>
        <form action="" @submit.prevent="saveInformation" ref="orgainizerForm"> 

            <div class="p-3">
              <div class="relative z-0">
                  <input type="text" required id="default_standard" v-model="form.name" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
              </div>
              <div>{{ errors?.name }}</div>
            </div>
            <div class="p-3">
              <div class="relative z-0">
                  <input type="email" required id="default_standard" v-model="form.email" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('Email') }} </label>
              </div>
              <div>{{ errors?.email }}</div>
            </div>
            <div class="p-3">
              <div class="relative z-0">
                  <input type="tel" required id="default_standard" v-model="form.phone_number" class="input-buja peer" placeholder=" " />
                  <label for="default_standard" class="float-label-buja">{{ $t('telephoneMsg') }} </label>
              </div>
              <div>{{ errors?.phone_number }}</div>
            </div>
            <div class=" p-3 ">
              <label for="">Logo:</label>
              <input @change="onFileSelected" type="file"   name="" id="" class="cursor-pointer py-2 px-1 w-full bg-[#141625] "/>
            </div>
  
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button  @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../../api.js'
  import result from '../../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        data(){
            return{
            form:{name:"",logo:"",phone_number:'',email:''},
            loading:null,
            organizers:[],
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../../Global/Loading.vue').default
        },
        mounted()
        {             
           if(this.$store.state.organizerId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.organizer;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
                console.log(this.$store.state.organizerId)
  
        },
        computed:{
        ...mapState(['organizerId','organizer']),
    },
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.organizerId=null;
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },

                 saveInformation() {
                  
                  this.loading = true;
                       if (this.form["name", "logo"] == "") return;

                       const org = new FormData()
                       org.append("name", this.form.name);
                       org.append("email", this.form.email);
                       org.append("phone_number", this.form.phone_number);
                       if(this.form.logo)
                       {
                        org.append("logo", this.form.logo);

                       }
                       

            if (this.organizerId == null) {
                api.post(
                    "/organiser",
                    org
                )
                    .then((resp) => {
                      this.loading = false;
                      this.organizers = resp.data;
                      this.successAllert()
                      this.form = { name:"",logo:"",email:'',phone_number:''};
                     
                    })
                    .catch((err) => {
                      this.loading = false;
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
            } else {
                api.patch(
                    "/organiser/" + this.organizerId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.organizers = resp.data;
                        this.loading = false;
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
                },
                onFileSelected(event){
                    console.log(event);
                    this.form.logo=event.target.files[0]
                    console.log(event.target.files[0].name);
                }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>
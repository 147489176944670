<template>
    <Transition name="wrapModal">
            <wrapModal v-if="wrap ">
                <formData @close="getsortie(id),wrap=false"/>
            </wrapModal> 
    </Transition>
    <div style="margin: 120px 30px ; width: 80%" v-if="status">
        <button @click="click" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
              <span class="material-icons">keyboard_double_arrow_left</span>
        </button>
        <div class="block  justify-between  rounded-lg bg-gray p-6 shadow-lg dark:bg-neutral-700 max-w-lg mt-6">
            <div class="info">
                <p class="px-2 py-2  font-medium text-gray-900">Client:<span class="px-2">{{ client.name }}</span></p>
                <p class="px-2 py-2 font-medium text-gray-900">Condition:<span class="px-2" v-if="event!=null">{{ event.name }}</span><span v-else>{{ location.conditions }}</span></p>
            </div>
            <div class="info">
                <p class="px-2 py-2 font-medium text-gray-900">Details:<span class="px-2 leading-tight text-black-900">{{ location.detail }}</span></p>
                <p class="py-2 px-2">Deliverd Date: <span class="px-2">{{ date }}</span> </p>
            </div>
        </div>
        <div class="block rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-700 mt-6" >
            <button v-show="location.status=='pending'" v-if="cheftechrole || technicien || this.AdminRole" class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click=" toggleModal(id)">Ajouter Articles</button>
            <button v-show="location.status=='pending'" v-if="cheftechrole || this.AdminRole" class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click="confirm()">Confirm Reservation</button>
            <button v-show="location.status=='approved'" v-if="stockrole || this.AdminRole" class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click="sortie()">Autorisé sortie</button>
            <button @click="save" v-show="modif==true" class="rounded-sm p-3 bg-primary-600 hover:bg-green-400">save:</button>    
        <div class="relative overflow-x-auto  border sm:rounded-lg">
    <table class="w-full text-sm text-left  text-gray-500 dark:text-gray-400">
        <thead class="bg-black   border sm:rounded-lg">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Designation
                </th>
                <th scope="col" class="px-6 py-3">
                    quantite
                </th>
                
                <th scope="col" class="" v-if="location.status!=='done'">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700" v-for="(item,index) in articles" :key="item.id">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  <input type="text" class="bg-white" disabled :value="item.name" ref="NameMod">
                </th>
                <td class="px-6 py-4">
                    <input class="bg-white" @keyup="onchange(index)" type="number" disabled :value="item.quantity" ref="quantityMod">
                </td>
                <td class="">
                    <a href="#" v-if="location.status!=='done' && (cheftechrole || technicien || this.AdminRole)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" @click="Modifier(index)">Edit</a>
                    <a href="#" v-if="location.status!=='done' && (cheftechrole || this.AdminRole)" class="font-medium text-danger-600 dark:text-blue-500 hover:underline mx-4" @click="remove(item.id)">remove</a>

                </td>
            </tr>
            
        </tbody>
    </table>
</div>
        </div>
    </div>
    <div v-else>              
    <tableHolder class="w-full"/>
</div>


  
</template>

<script>

import api from '../../../api.js'
import result from '@/mixins/result';
import { mapMutations,mapState} from 'vuex';
export default {
    mixins:[result],
    data()
        {
            return{
                location:[],
                articles:[],
                client:"",
                date:"",
                event:"",
                modif:false,
                form:{
                    articles:[],
                    op:'mod',
                },
                status:null,
                wrap:null,
                roles:[],
                cheftechrole:"",
                technicien:"",
                stockrole:"",


            }
        },
        props:['id'],
        components:{
    'formData':require('./addnewForm.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,
},
computed:{
...mapState(['wrapModal','locationId','location']),
// locations(){
//         return  this.$store.state.location
//     }
},
mounted()
        {
            this.getsortie(this.id);
            this.getrole()
            

        },
methods:{
            ...mapMutations(['WRAP_MODAl','SIDE_BAR']),
        toggleModal(id){
            
            this.WRAP_MODAl();
            this.wrap=true
            this.$store.state.locationId=id;
            this.$store.state.locations_art=this.articles
           
        },
        getrole()
        {
            this.roles=this.getroles()
            console.log(this.roles)
            this.cheftechrole=this.roles.find((item)=>item.id==5)
            this.AdminRole=this.roles.find((item)=>item.id==1)
            this.technicien=this.roles.find((item)=>item.id==4)
            this.stockrole=this.roles.find((item)=>item.id==6)
        },
          getsortie(id)
        {
        api.get("/location/"+id)
        .then(resp => {
            this.location = resp.data[0]
            if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
            let client= JSON.stringify(resp.data[0].client)
            let events= JSON.stringify(resp.data[0].events)
            this.event=JSON.parse(events)
            this.client=JSON.parse(client)
            this.date=resp.data[0].nb_days.substr(0, 10)
           // let articles=JSON.stringify(resp.data[0].articles)
            this.articles=JSON.parse(resp.data[0].articles)
            console.log(this.articles)
        })
        .catch(err => {
            console.error(err)
        })
        },
        click()
        {
          this.$router.push('/Location')
          this.$store.state.wrapModal=null
        },
        
        Modifier(index)
        {
            this.modif=true
            let a=this.$refs.quantityMod[index]
            console.log(a)
            a.disabled=false
            a.class="bg-gray"
        },
       
        remove(index)
        {
            console.log(index)
         //   const indexrw = this.articles.indexOf(index);
        // let a=this.$refs.quantityMod[index];
        // console.log(a.value)
            this.articles = this.articles.filter(function(el) { return el.id !== index; }); 
            this.modif=true
            console.log(this.articles)

        },
        save()
        {
            this.form.articles=this.articles

            api.patch(
                "/location/" + this.id,
                this.form)
                .then((resp) => {
                  //this.locations = resp.data;
                  this.loading = false;
                  this.successAllert()
                  this.modif=false;
                  let a=this.$refs.quantityMod
                      a.forEach(element => {
                        element.disabled=true
                        console.log(element.value)
                    });   
                //  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                    alert(JSON.stringify(err.data.data))
                  //this.errorAlert()
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors; 
                });
        },
        sortie()
        {
            this.form.op='done'
            this.form.articles=this.articles
            api.patch("/location/" +this.id,this.form)
        .then(resp => {
            this.successAllert()
            //this.categories = resp.data.data 
            this.getsortie(this.id)
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
        },
        confirm()
        {
            this.form.op='approve'
            this.form.articles=this.articles
        this.confirmres().then((result) => 
        {
        if (result.isConfirmed) {
        api.patch("/location/" +this.id,this.form)
        .then(resp => {
            this.successAllert()
            //this.categories = resp.data.data 
            this.getsortie(this.id)
        })
        .catch(err => {
            console.error(err)
            this.errorAlert()
        })
    }})
    },
        onchange(index)
        {
                this.articles[index].quantity= this.$refs.quantityMod[index].value  
                console.log(this.articles)    
        },
        }
}

</script>

<style>

</style>
<template>
    <div class="w-full">
        <EventCategList/>
    </div>
</template>

<script>
    export default {
        
        components:{
            'EventCategList':require('@/components/Event/Event Category/EventCategList.vue').default
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
<div class="navbar no_print"> 	
	<nav class="navbar-bujaEvent">
	<div class="px-3 py-3 lg:px-5 lg:pl-3">
		<div class="flex items-center justify-between">
		<div class="flex items-center justify-start">
			<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 text-sm text-gray-900 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
				<span class="sr-only">Open sidebar</span>
				<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
				<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
				</svg>
			</button>
			<a href="#" class="flex ml-2 md:mr-24">
			<div class="">
				<img src="@/assets/logo.png" alt="Vue" class="h-8 mr-3 md:hidden"/> 
				</div>
			<span class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white md:hidden">Buja Event</span>
			</a>
		</div>
		<div class="flex items-center">
			<div class="flex items-center ml-3">
				<div class="">
				<button type="button" class="flex text-sm" aria-expanded="false" data-dropdown-toggle="dropdown-user">
					<img :src="image" alt="Dukore Logo" class="w-8 h-8 rounded-full  bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
					<span class="text-black text-lg ml-1 max-sm:hidden">{{ username }}</span>
				</button>
				</div>
				<div class="z-50 hidden my-4 text-base list-none bg-white bg-clip-padding divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
				
				<ul class="w-40" role="none">
					<li>
					<a class="dropDown-items "
						href="#" data-te-dropdown-item-ref >
						<span class="material-icons">person</span>
						<span class="ml-2">{{$t("ProfileMsg")}}</span>
					</a>
					</li>
					<li @click="refresh">
					<router-link to="/settings" class="dropDown-items "
						href="#" data-te-dropdown-item-ref >
						<span class="material-icons">settings</span>
						<span class="ml-2">{{ $t('settingMsg') }}</span>
					</router-link>
					</li>
					<li>
					<router-link to="" @click="logout" class="dropDown-items "
						href="#" data-te-dropdown-item-ref >
						<span class="material-icons">logout</span>
						<span class="ml-2">{{$t ('logoutMsg')}}</span>
					</router-link> 
					</li>
				</ul>
				</div>
			</div>
			</div>
		</div>
	</div>
	</nav> 
</div>

<aside id="logo-sidebar" class="no_print bg-[#141625] fixed top-0 left-0 z-40 w-64 h-screen pt-3 max-sm:pt-20 transition-transform -translate-x-full border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
   <div class="h-full  overflow-y-auto bg-[#141625] text-white dark:bg-gray-800">
	<div class="logo flex justify-center max-sm:hidden">
			<img src="@/assets/logo.png" alt="Vue" /> 
	 </div>
	  <h3 class="text-gray-400 mb-3 ml-3 uppercase text-sm max-sm:hidden">Menu</h3>
      <ul class="space-y-2">
		 <li>
			<router-link to="/" class="router-link">
				<span class="material-icons">dashboard</span>
				<span class="ml-3 capitalize text">{{ $t('dashboardMsg') }}</span>
			</router-link>
		 </li>
		 <li>
			<div @click="dropDownUser()" class="router-link ">
				<span class="material-icons">assignment_ind</span>
				<div class="flex justify-between w-full items-center" >
					<span class="text-[15px] text-gray-200 ml-3 text capitalize">{{$t ('userMsg') }}</span>
					<span class="text-sm" :class="`${collapse ? 'rotate-90' : ''}`">
					<i class="bi bi-chevron-right"></i>
					</span>
				</div>
        	</div>
			<div class="submenu " :class="`${collapse ? 'transition-[opacity 0.3s ease-in-out' : 'hidden'}`">					
				<router-link to="/users" class="router-link p-3 ">
					<span class="ml-3 capitalize text">{{$t ('userMsg') }}</span>
				</router-link> 
				 
			</div>
		 </li>
		 <li>
			<div @click="dropDownstock()" class="router-link text">
				<span class="material-icons">house</span>
				<div class="flex justify-between w-full items-center" >
					<span class="text-[15px] text-gray-200 ml-3 text capitalize">{{$t ('Stock') }}</span>
					<span class="text-sm" :class="`${dropdownSt ? 'rotate-90' : ''}`">
					<i class="bi bi-chevron-right"></i>
					</span>
				</div>
        	</div>
			<div class=" submenu " :class="`${dropdownSt ? 'transition-[opacity 0.3s ease-in-out' : 'hidden'}`">
				<!--<router-link to="/stock" class="router-link p-3">
				   
				   <span class="ml-3 capitalize text">{{ $t('stockMsg') }}</span>
			    </router-link>-->
				<router-link to="/location" class="router-link p-3">
				  
				   <span class="ml-3 capitalize text">{{ $t('Sortie') }}</span>
			    </router-link>
				<router-link to="/return" class="router-link p-3">
				    
				    <span class="ml-3 capitalize text">{{ $t('Retour') }}</span>
			    </router-link>

			</div>
		 </li>
		

		 <li>
			<div @click="dropDownArticle()" class="router-link text">
				<span class="material-icons">house</span>
				<div class="flex justify-between w-full items-center" >
					<span class="text-[15px] text-gray-200 ml-3 text capitalize">{{$t ('Equipment') }}</span>
					<span class="text-sm" :class="`${dropdownAt ? 'rotate-90' : ''}`">
					<i class="bi bi-chevron-right"></i>
					</span>
				</div>
        	</div>
			<div class=" submenu " :class="`${dropdownAt ? 'transition-[opacity 0.3s ease-in-out' : 'hidden'}`">
		
			    <router-link to="/category" class="router-link ">
			    	
			    	<span class="ml-3 capitalize text">{{ $t('categoryMsg') }}</span>
			    </router-link>
				<router-link to="/article" class="router-link ">
				
				<span class="ml-3 capitalize text">{{ $t('articleMsg') }}</span>
			</router-link>
		    </div>
		 </li>
		 
		

		 <li>
			<router-link to="/service" class="router-link ">
				<span class="material-icons">account_tree</span>
				<span class="ml-3 capitalize text">{{ $t('serviceMsg') }}</span>
			</router-link>
		 </li>
			<li>
				<router-link to="/client" class="router-link ">
					<span class="material-icons">group</span>
					<span class="ml-3 capitalize text">{{ $t('clientMsg') }}</span>
				</router-link>
			</li>

			<li>
				<router-link to="/facture" class="router-link">
					<span class="material-icons">payments</span>
					<span class="ml-3 capitalize text">{{ $t('RecieptMsg')}}</span>
				</router-link>
			</li>
		
			<!--<li>
				<router-link to="/requisition" class="router-link">
					<span class="material-icons">assignment_ind</span>
					<span class="ml-3 capitalize text">{{ $t('requisitionMsg') }}</span>
				</router-link>
			</li>-->
			<li>
				<div @click="dropDown()" class="router-link ">
				<span class="material-icons">festival</span>
				<div class="flex justify-between w-full items-center">
					<span class="text-[15px] text-gray-200 ml-3 text capitalize">{{$t("EventMsg")}}</span>
					<span class="text-sm" :class="`${dropDownIt ? 'rotate-90' : ''}`">
					<i class="bi bi-chevron-right"></i>
					</span>
				</div>
				</div>

				<div class="submenu" :class="`${dropDownIt ? 'transition-[opacity 0.3s ease-in-out' : 'hidden'}`">
						
					<router-link to="/ticket_type" class="router-link p-3 ">
						<span class="ml-3 text">{{$t("TickeTypeMsg")}}</span>
					</router-link> 
					<router-link to="/eventCategory" class="router-link p-3">
						<span class="ml-3 text">{{$t('EventCategoryMsg')}}</span>
					</router-link>
					<router-link to="/organiser" class="router-link p-3">
						<span class="ml-3 text">{{$t("organizerMsg")}}</span>
					</router-link> 
					<router-link to="/event" class="router-link p-3">
						<span class="ml-3 textcapitalize">{{$t('EventMsg')}}</span>
					</router-link>
					<router-link to="/event_price" class="router-link p-3">
						<span class="ml-3 text">{{$t('EventPriceMsg')}}</span>
					</router-link> 
					<router-link to="/event_order" class="router-link p-3">
						<span class="ml-3 text">{{$t('EventOrderMsg')}}</span>
					</router-link> 
					<router-link to="/event_detail" class="router-link p-3 ">
						<span class="ml-3 text">{{$t('EventDetailMsg')}}</span>
					</router-link> 
				</div>	
			</li>
      </ul>
   </div>
</aside>

<div class="sm:ml-64">
   <div class="">
	<router-view/>
   </div>
</div>


</template>

<script>
import {mapState, mapMutations } from 'vuex';


export default {
	data(){
		return{
			collapse:null,
			dropDownIt:null,
			dropdownSt:null,
			dropdownAt:null,
			user:this.$store.getters.user,
			username:'',
			image:'',
			roles:[],
			
			

		}
	},
	mounted(){
		this.getuser()
		console.log('user :',this.user);  
	},
	 computed:{
			...mapState(['is_expanded'])		
	},
	methods:{
		...mapMutations(['SIDE_BAR','LOGOUT']),
		ToggleMenu(){
			this.SIDE_BAR()
			this.collapse=false
			this.dropDownIt=false
			this.dropdownSt=false
			this.dropdownAt=false
		},
		getuser(){

			    
                let userlogged=JSON.parse(localStorage.getItem('userBujEvent'))
                this.username=Object.values(userlogged)[0].name                
                this.image=Object.values(userlogged)[0].image
                this.id=Object.values(userlogged)[0].id
				console.log(userlogged.token)
			    userlogged.roles.forEach(element => {
					this.roles.push({id:element.id,name:element.name})
				});
				console.log(this.roles)
				let techrole=this.roles.find((item)=>item.id==5)
				if(techrole)
				{
					console.log('hi')
				}
				else{
					console.log('not')
				}
				console.log(techrole)

				   
             },
		logout() {
       this.LOGOUT()
        // localStorage.removeItem('token')
        // this.$router.push('/login')
        
    },
	refresh(){
		this.$router.go(0)
	},
	
		dropDownUser(){
		this.collapse=!this.collapse
		this.$store.state.is_expanded=true
	},
	 dropDown() {
		this.dropDownIt=!this.dropDownIt
		this.$store.state.is_expanded=true
    },
	dropDownstock()
	{		
		this.dropdownSt=!this.dropdownSt
		this.$store.state.is_expanded=true
	},
	dropDownArticle()
	{		
		this.dropdownAt=!this.dropdownAt
		this.$store.state.is_expanded=true
	}

	}
}
</script>

<style lang="scss" scoped>

@media print {
    .no_print{
        display: none;
    }
}
.navbar-bu{
	position: sticky;
	top: 0;
	width: 100%;
	 @media(max-width:640px){
		position: fixed !important;
		top:0;
		z-index:50;
		background-color: white;
	 }

}

.navbar{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}

aside {
	
	&::-webkit-scrollbar {
    display: none;
  }
	
	.router-link:hover {
				background-color: var(--dark-alt);
				.material-icons, .text {
					color: var(--primary);
				}
			}

			.logo {
		margin-bottom: 0.8rem;

		img {
			width: 4rem;
		}
	}

	.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
	.router-link-exact-active {
					background-color: var(--dark-alt);
					border-right: 5px solid var(--primary);
	
					.material-icons, .text {
						color: var(--primary);
					}
				}
}
</style>